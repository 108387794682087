var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-side-menu"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"h-100",attrs:{"id":"left-side-menu-container","data-simplebar":""}},[_c('ul',{staticClass:"metismenu side-nav"},[_c('li',{staticClass:"side-nav-title side-nav-item"},[_vm._v("Navigation")]),_c('li',{class:[
        'side-nav-item',
        _vm.routeStartsWith('home') ? 'mm-active' : ''
      ]},[_c('router-link',{staticClass:"side-nav-link",attrs:{"to":'/'}},[_c('i',{staticClass:"uil-dashboard"}),_c('span',[_vm._v(" Dashboard ")])])],1),_c('li',{class:[
        'side-nav-item',
        _vm.routeStartsWith('products.') ? 'mm-active' : ''
      ]},[_c('router-link',{staticClass:"side-nav-link",attrs:{"to":'/products'}},[_c('i',{staticClass:"mdi mdi-beer-outline"}),_c('span',[_vm._v(" Products ")])])],1),(!_vm.adminRoutes)?_c('li',{class:[
        'side-nav-item',
        _vm.routeStartsWith('orders.') ? 'mm-active' : ''
      ]},[_c('router-link',{staticClass:"side-nav-link",attrs:{"to":'/orders'}},[_c('i',{staticClass:"uil-box"}),_c('span',[_vm._v(" Orders ")])])],1):_vm._e(),(!_vm.adminRoutes)?_c('li',{class:[
        'side-nav-item',
        _vm.routeStartsWith('cart.') ? 'mm-active' : ''
      ]},[_c('router-link',{staticClass:"side-nav-link",attrs:{"to":'/cart'}},[_c('i',{staticClass:"uil-shopping-cart-alt"}),_c('span',[_vm._v(" Cart ")]),_c('span',{staticClass:"badge badge-info badge-pill float-right"},[_vm._v(" "+_vm._s(_vm.cartItemCache)+" ")])])],1):_vm._e(),(_vm.adminRoutes)?_c('li',{class:[
        'side-nav-item',
        _vm.routeStartsWith('admin.clients') ? 'mm-active' : ''
      ]},[_c('router-link',{staticClass:"side-nav-link",attrs:{"to":'/admin/clients'}},[_c('i',{staticClass:"uil-users-alt"}),_c('span',[_vm._v(" Clients ")])])],1):_vm._e(),(_vm.adminRoutes)?_c('li',{class:[
        'side-nav-item',
        _vm.routeStartsWith('admin.orders') ? 'mm-active' : ''
      ]},[_c('router-link',{staticClass:"side-nav-link",attrs:{"to":'/admin/orders'}},[_c('i',{staticClass:"uil-box"}),_c('span',[_vm._v(" Orders ")])])],1):_vm._e(),(_vm.adminRoutes)?_c('li',{class:[
        'side-nav-item',
        _vm.routeStartsWith('admin.applications') ? 'mm-active' : ''
      ]},[_c('router-link',{staticClass:"side-nav-link",attrs:{"to":'/admin/applications'}},[_c('i',{staticClass:"uil-box"}),_c('span',[_vm._v(" Applications ")])])],1):_vm._e(),(_vm.adminRoutes)?_c('li',{class:[
        'side-nav-item',
        _vm.routeStartsWith('admin.cart') ? 'mm-active' : ''
      ]},[_c('router-link',{staticClass:"side-nav-link",attrs:{"to":'/admin/cart'}},[_c('i',{staticClass:"uil-shopping-cart-alt"}),_c('span',[_vm._v(" Cart ")]),_c('h4',{directives:[{name:"show",rawName:"v-show",value:(_vm.cartItemCache > 0),expression:"cartItemCache > 0"}],staticClass:"float-right",staticStyle:{"margin":"0"}},[_c('span',{staticClass:"badge badge-light badge-pill"},[_vm._v(" "+_vm._s(_vm.cartItemCache)+" ")])])])],1):_vm._e()]),_c('div',{staticClass:"clearfix"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"logo text-center logo-light",attrs:{"href":"/"}},[_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":"/assets/images/logo-wide.svg","height":"30"}})]),_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":"/assets/images/logo-dark.svg","alt":"","height":"16"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"logo text-center logo-dark",attrs:{"href":"/"}},[_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":"/assets/images/logo-wide.svg","height":"30"}})]),_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":"/assets/images/logo-dark.svg","alt":"","height":"16"}})])])
}]

export { render, staticRenderFns }