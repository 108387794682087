/* eslint-env jquery */

<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>
        
        <!-- Start Content-->
        <div class="container-fluid">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box" v-if="apiResponse.loading">
                <h4 class="page-title">Wholesale Application - Loading... </h4>
              </div>
              <div class="page-title-box" v-else-if="apiResponse.error">
                <h4 class="page-title">Wholesale Application - Error </h4>
              </div>
              <div class="page-title-box" v-else-if="application == null">
                <h4 class="page-title">Wholesale Application - Not Found </h4>
              </div>
              <div class="page-title-box" v-else>
                <h4 class="page-title">Wholesale Application - {{ application.venueName }} </h4>
              </div>
            </div>
          </div>
          <!-- End Page Title --> 
          <!-- Start Loading / Errors -->
          <div class="d-flex justify-content-center" v-if="apiResponse.loading">
            <div class="spinner-border avatar-lg text-primary" role="status"></div>
          </div>
          <div class="row" v-else-if="apiResponse.error">
            <div class="col-12">
              <div class="card">
                <!-- Start Error Message -->
                <div class="card-body">
                  <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Api Error!</h4>
                    <p>There was an unexpected error when loading data from the API. Click the button below to try again.</p>
                    <p class="mb-0">
                      <button type="button" class="btn btn-info" v-on:click="fetchData">
                        <i class="mdi mdi-refresh mr-1"></i> Retry
                      </button>
                    </p>
                  </div>
                </div>
                <!-- End Error Message -->
              </div>
            </div>
          </div>
          <!-- End Loading / Errors -->
          <!-- Start Applications Page -->
          <div class="row" v-else>
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-centered w-100 dt-responsive nowrap" id="products-datatable">
                      <thead class="thead-light">
                        <tr>
                          <th class="all">Description</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody v-if="application == null">
                        <tr>
                          <td colspan="2">
                            <div class="d-flex justify-content-center">
                              <div class="spinner-border avatar-lg text-primary" role="status" v-show="application == null"></div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td>
                            Application ID
                          </td>
                          <td>
                            {{ application.applicationId }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Venue
                          </td>
                          <td>
                            <strong>Name</strong>: {{ application.venueName }} <br>
                            <strong>ABN</strong>: {{ application.abn }} <br>
                            <strong>License</strong>: {{ application.license }} <br>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Venue Address
                          </td>
                          <td>
                            {{ application.venueAddress.line1 }} <br>
                            {{ application.venueAddress.line2 }} <br>
                            {{ application.venueAddress.city }},  {{ application.venueAddress.postcode }}, {{ application.venueAddress.state }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Venue Delivery Address
                          </td>
                          <td>
                            {{ application.deliveryAddress.line1 }} <br>
                            {{ application.deliveryAddress.line2 }} <br>
                            {{ application.deliveryAddress.city }},  {{ application.deliveryAddress.postcode }}, {{ application.deliveryAddress.state }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Contact
                          </td>
                          <td>
                            <strong>{{ application.contactName }}</strong> <br>
                            {{ application.contactEmail }} <br>
                            {{ application.contactMobileNumber }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Accounts Receivable
                          </td>
                          <td>
                            <strong>{{ application.accRecName }}</strong> <br>
                            {{ application.accRecEmail }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                
                  <!-- Start Application Buttons -->
                  <div class="mt-4" v-if="application != null">
                    <div class="text-right">
                      <button class="btn btn-primary ml-2" v-on:click="approveApplication()">
                        <i class="mdi mdi-check-bold"></i> Approve
                      </button>
                      <button class="btn btn-danger ml-2" data-toggle="modal" data-target="#fill-danger-modal">
                        <i class="mdi mdi-delete"></i> Deny
                      </button>
                    </div>
                    <div id="fill-danger-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="fill-danger-modalLabel" aria-hidden="true">
                      <div class="modal-dialog">
                        <div class="modal-content modal-filled bg-danger">
                          <div class="modal-header">
                            <h4 class="modal-title" id="fill-danger-modalLabel">Deny Application for {{ application.venueName }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                          </div>
                          <div class="modal-body">
                            You are about to deny the wholesale application for <strong>{{ application.venueName }}</strong>, this action will require a re-application from the venue to be reversed. <br>
                            <br>
                            Please confirm your action below or click close to cancel.
                          </div>
                          <div class="modal-footer">
                            <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-outline-light" v-on:click="denyApplication()">Deny Application</button>
                          </div>
                        </div><!-- /.modal-content -->
                      </div><!-- /.modal-dialog -->
                    </div>
                  </div>
                  <!-- End Application Buttons -->
                </div> <!-- end card-body-->
              </div> <!-- end card-->
            </div> <!-- end col -->
          </div>
          <!-- End Product Page -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
export default {
  props: [
    'applicationId'
  ],
  data() {
    return {
      apiResponse: {
        loading: true,
        error: false
      },
      application: null,
    }
  },
  methods: {
    approveApplication: function () {
      $.NotificationApp.send(
        "Success!",
        "You have approved this application and they should be setup in Xero shortly!",
        "top-center", "rgb(0,0,0)",
        "success"
      );
    },
    denyApplication: function () {
      var api_url = '/api/admin/application/' + this.applicationId;

      axios.delete(api_url).then(response => {
        if(response) {
          $("#fill-danger-modal").modal('toggle');
          $.NotificationApp.send(
            "Success!",
            "Application was successfully denied!",
            "top-center", "rgb(0,0,0)",
            "success"
          );
        }
      }).catch(error => {
        if (error.response) {
          $.NotificationApp.send(
            "Failure!",
            "There was an error denying this application...",
            "top-center", "rgb(0,0,0)",
            "warning"
          );
        }
      });
    },
    fetchData: function() {
      // Send POST request
      var api_url = '/api/admin/application/' + this.applicationId;
      this.apiResponse.loading = true;
      this.apiResponse.error = false;

      axios
        .get(api_url)
        .then(response => {
          this.application = response.data.application;
          this.apiResponse.loading = false;
        })
        .catch(error => {
          this.apiResponse.loading = false;
          if (error.response && error.response.status >= 500 && error.response.status < 600) {
            this.apiResponse.error = false;
          }
        });
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>
