/* eslint-env jquery */

<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>
        
        <!-- Start Content-->
        <div class="container-fluid">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Orders List</h4>
              </div>
            </div>
          </div>
          <!-- End Page Title --> 
          <!-- Start Loading / Errors -->
          <div class="d-flex justify-content-center" v-if="apiResponse.loading">
            <div class="spinner-border avatar-lg text-primary" role="status"></div>
          </div>
          <div class="row" v-else-if="apiResponse.error">
            <div class="col-12">
              <div class="card">
                <!-- Start Error Message -->
                <div class="card-body">
                  <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Api Error!</h4>
                    <p>There was an unexpected error when loading data from the API. Click the button below to try again.</p>
                    <p class="mb-0">
                      <button type="button" class="btn btn-info" v-on:click="fetchData">
                        <i class="mdi mdi-refresh mr-1"></i> Retry
                      </button>
                    </p>
                  </div>
                </div>
                <!-- End Error Message -->
              </div>
            </div>
          </div>
          <!-- End Loading / Errors -->
          <!-- Start Orders Page -->
          <div class="row" v-else>
            <div id="warning-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
              <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-body p-4">
                      <div class="text-center">
                        <i class="dripicons-warning h1 text-warning"></i>
                        <h4 class="mt-2">Confirm Cancellation</h4>
                        <p class="mt-3">Please confirm the cancellation of the order for {{ cancelOrder.VenueName }} at ${{ Number(cancelOrder.Total).toFixed(2) }} and select a cancellation reason.</p>
                        <div class="form-group">
                          <select class="form-control" v-model="cancelOrderReason">
                            <option value="STOCK">Stock is no longer required</option>
                            <option value="INCORRECT">Order was entered incorrectly</option>
                            <option value="CHANGE">Order will be changed and re-submitted</option>
                            <option value="UNKNOWN">Unknown reason</option>
                            <option value="OTHER">Other</option>
                          </select>
                        </div>
                        <p>
                          <small>Note: Depending on when the order is cancelled, the same delivery time slot may not be available if you need to re-submit your order. Delays might be incurred.</small>
                        </p>
                        <button type="button" class="btn btn-warning mr-1" data-dismiss="modal" v-on:click="cancelOrderConfirm(cancelOrder)">Cancel Order</button>
                        <button type="button" class="btn btn-light mr-1" data-dismiss="modal">Dismiss</button>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div id="clientsTable" v-if="apiResponse.loading == false">
                    <v-client-table v-if="orders" :data="orders" :columns="columns" :options="options">
                      <div slot="Customer" slot-scope="{row}">
                        {{ row.VenueName }} <br>
                        {{ row.Username }}
                      </div>
                      <div slot="ShippingDate" slot-scope="{row}">
                        {{ daysFullName[new Date(row.ShippingDate).getDay()] }} <br>
                        {{ formatDate(row.ShippingDate) }}
                      </div>
                      <div slot="Total" slot-scope="{row}">
                        ${{ Number(row.Total).toFixed(2) }}
                      </div>
                      <div slot="Action" slot-scope="{row}">
                        <router-link :to="'/admin/order/' + row.Id " class="btn text-muted d-none d-sm-inline-block btn-link font-weight-semibold">
                          <button type="button" class="btn btn-info btn-sm"><i class="mdi mdi-eye mr-1"></i> <span>View</span> </button>
                        </router-link>
                        <button v-if="row.OrderStatus == 'Failed'" type="button" class="btn btn-info btn-sm" v-on:click="retryOrder(row.Id)">
                          <i class="mdi mdi-refresh mr-1"></i> <span>Retry</span>
                        </button>
                        <button v-if="row.OrderStatus == 'In Progress'" type="button" class="btn btn-danger btn-sm" v-on:click="cancelOrderPrompt(row)" data-toggle="modal" data-target="#warning-alert-modal">
                          <i class="mdi mdi-cancel mr-1"></i> <span>Cancel</span>
                        </button>
                      </div>
                    </v-client-table>
                  </div>
                </div> <!-- end card-body-->
              </div> <!-- end card-->
            </div> <!-- end col -->
          </div>
          <!-- End Product Page -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
const orders_api_url = '/api/admin/order';

export default {
  data() {
    return {
      apiResponse: {
        loading: true,
        error: false
      },
      loadingData: true,
      daysFullName: [
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
      ],
      monthsFullName: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      columns: ['Customer', 'XeroInvoiceId', 'ShippitTrackingId', 'Items', 'ShippingDate', 'OrderStatus', 'Total', 'Action'],
      orders: [],
      cancelOrder: {
        VenueName: "",
        total: 0
      },
      cancelOrderReason: "STOCK",
      options: {
        filterable: ['Customer', 'XeroInvoiceId', 'ShippitTrackingId', 'Items', 'ShippingDate', 'OrderStatus', 'Total'],
        orderBy: {
          column: 'XeroInvoiceId',
          ascending: false
        },
        headings: {
          XeroInvoiceId: 'Xero Invoice',
          ShippitTrackingId: 'Tracking Id',
          OrderStatus: 'Status',
          ShippingDate: 'Date'
        }
      }
    }
  },
  methods: {
    formatDate: function(data) {
      let date = new Date(data);
      return [
        date.getDate(),
        this.monthsFullName[date.getMonth()],
        date.getFullYear()
      ].join(" ");
    },
    fetchData: function() {
      this.apiResponse.loading = true;
      this.apiResponse.error = false;

      axios.get(orders_api_url)
        .then(response => {
          this.orders = response.data.Items;
          this.apiResponse.loading = false;
        })
        .catch(error => {
          if (error) {
            this.apiResponse.error = true;
            this.apiResponse.loading = false;
          }
        });
    },
    async cancelOrderConfirm(order) {
      let postData = {
        reason: this.cancelOrderReason,
      };
      axios.post('/api/admin/order/' + order.Id + '/cancel', postData)
        .then(response => {
          if (response.data.success) {
            this.$bvModal.hide('cancelOrderModal');
            
            $.NotificationApp.send(
              "Success!",
              "The order was successfully cancelled.",
              "top-center", "rgb(0,0,0)",
              "success"
            );
          }
          else {
            $.NotificationApp.send(
              "Warning!",
              "The order could not be cancelled because of '" + response.data.message + "'.",
              "top-center", "rgb(0,0,0)",
              "warning"
            );
          }
        })
        .catch(error => {
          $.NotificationApp.send(
            "Error!",
            "There was an error cancelling this order, please check the logs.",
            "top-center", "rgb(0,0,0)",
            "error"
          );
          return error;
        });

        this.cancelOrderReason = "STOCK";
        this.cancelOrder = {
          VenueName: "",
          total: 0
        };
    },
    async cancelOrderPrompt(order) {
      if(order.OrderStatus.toLowerCase() == 'in progress') {
        this.cancelOrderReason = "STOCK";
        this.cancelOrder = order;
      }
    },
    async retryOrder(orderId) {
      const objIndex = this.orders.findIndex((obj => obj.Id == orderId));
      this.orders[objIndex].OrderStatus = 'Retrying';

      await axios.post('/api/admin/order/' + orderId + '/retry');

      $.NotificationApp.send(
        "Success!",
        "The order is being retried in the background, please check back again later to verify status.",
        "top-center", "rgb(0,0,0)",
        "success"
      );
    }
  },
  async mounted() {
    this.fetchData();
  }
}
</script>
