<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>
        
        <!-- Start Content-->
        <div class="container-fluid">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Dashboard</h4>
              </div>
            </div>
          </div>
          <!-- End Page Title --> 
          <!-- Start Dashboard Numeric Blocks -->
          <div class="row">
            <div class="col-lg-3">
              <div class="card">
                <div class="card-body text-center">
                  <i class="dripicons-briefcase text-muted" style="font-size: 24px;"></i>
                  <h3><span>{{ dashboardData.ProductsCount }}</span></h3>
                  <p class="text-muted font-15 mb-0">Total Products</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card">
                <div class="card-body text-center">
                  <i class="dripicons-briefcase text-muted" style="font-size: 24px;"></i>
                  <h3><span>{{ dashboardData.CompletedOrders }}</span></h3>
                  <p class="text-muted font-15 mb-0">Completed Orders</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card">
                <div class="card-body text-center">
                  <i class="dripicons-briefcase text-muted" style="font-size: 24px;"></i>
                  <h3><span>{{ dashboardData.PendingOrders }}</span></h3>
                  <p class="text-muted font-15 mb-0">Pending Orders</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card">
                <div class="card-body text-center">
                  <i class="dripicons-briefcase text-muted" style="font-size: 24px;"></i>
                  <h3><span>{{ dashboardData.OtherValue }}</span></h3>
                  <p class="text-muted font-15 mb-0">Total ?</p>
                </div>
              </div>
            </div>
          </div>
          <!-- End Dashboard Numeric Blocks -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
export default {
  data() {
    return {
      dashboardData: {},
    }
  },
  methods: {
  },
  mounted() {
    var api_url = '/api/client/dashboard';
    // Send POST request
    axios
      .get(api_url)
      .then(response => (this.dashboardData = response.data));
  }
}
</script>
