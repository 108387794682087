/* eslint-env jquery */

<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>
        
        <!-- Start Content-->
        <div class="container-fluid">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Confirm Cart</h4>
              </div>
            </div>
          </div>
          <!-- End Page Title --> 
          <!-- Start Loading / Errors -->
          <div class="d-flex justify-content-center" v-if="apiResponse.loading">
            <div class="spinner-border avatar-lg text-primary" role="status"></div>
          </div>
          <div class="row" v-else-if="apiResponse.error">
            <div class="col-12">
              <div class="card">
                <!-- Start Error Message -->
                <div class="card-body">
                  <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Api Error!</h4>
                    <p>There was an unexpected error when loading data from the API. Click the button below to try again.</p>
                    <p class="mb-0">
                      <button type="button" class="btn btn-info" v-on:click="fetchData">
                        <i class="mdi mdi-refresh mr-1"></i> Retry
                      </button>
                    </p>
                  </div>
                </div>
                <!-- End Error Message -->
              </div>
            </div>
          </div>
          <!-- End Loading / Errors -->
          <!-- Start Cart Page -->
          <div class="row" v-show="orderSubmissionErrors.length > 0">
            <div class="col-12">
              <div class="alert alert-danger" role="alert">
                <h4 class="alert-heading">Please fix these issues with your order before continuing.</h4>
                <ul v-for="error in orderSubmissionErrors" v-bind:key="error">
                  <li>{{ error }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row" v-show="apiResponse.loading == false && apiResponse.error == false">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <!-- Steps Information -->
                  <div class="tab-content">

                    <!-- Billing Content-->
                    <div class="tab-pane show active" id="billing-information">
                      <div class="row">
                        <div class="col-lg-8">
                          <h4 class="mt-2">Billing information</h4>

                          <p class="text-muted mb-4">
                            Fill the form below in order to
                            send you the order's invoice.
                          </p>
                          <form v-on:submit.prevent>
                            <div class="row">
                              <div class="col-md-6">
                                <!-- Selector for Venue -->
                                <div class="form-group">
                                  <label>Venue</label>
                                  <select class="form-control select2" data-toggle="select2" v-model="selectedCustomer.venueId" :disabled="venues.length == 0">
                                    <option v-for="item in venues" v-bind:key="item.Id" v-bind:value="item.Id">
                                      {{ item.Name }}
                                    </option>
                                  </select>
                                </div>
                                <!-- End Selector for Venue -->
                              </div>
                              <div class="col-md-6">
                                <!-- Selector for Client Contact -->
                                <div class="form-group">
                                  <label>Email Address</label>
                                  <select class="form-control select2" data-toggle="select2" v-model="selectedCustomer.emailAddress" :disabled="selectedCustomer.venueContacts.length == 0">
                                    <option v-for="item in selectedCustomer.venueContacts" v-bind:key="item.Email" v-bind:value="item.Email">
                                      {{ item.FirstName }} {{ item.LastName }} {{ item.Email }}
                                    </option>
                                  </select>
                                </div>
                                <!-- End Selector for Client Contact -->
                              </div>
                            </div>
                          </form>
                          <form v-on:submit.prevent v-show="selectedCustomerCompleted">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="billing-first-name">First Name</label>
                                  <input class="form-control" type="text" placeholder="Enter your first name" id="billing-first-name" v-model="selectedCustomer.venueContact.firstName" />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="billing-last-name">Last Name</label>
                                  <input class="form-control" type="text" placeholder="Enter your last name" id="billing-last-name" v-model="selectedCustomer.venueContact.lastName" />
                                </div>
                              </div>
                            </div> <!-- end row -->
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="billing-email-address">Email Address <span class="text-danger">*</span></label>
                                  <input class="form-control" type="email" placeholder="Enter your email" id="billing-email-address" v-model="selectedCustomer.venueContact.emailAddress" />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label for="billing-phone-number">Phone Number </label>
                                  <input class="form-control" type="text" placeholder="61 123 456 789" id="billing-phone-number" v-model="selectedCustomer.venueContact.phoneNumber" />
                                </div>
                              </div>
                            </div> <!-- end row -->
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="shipping-instructions">Shipping Instructions <span class="text-danger">*</span></label>
                                  <textarea class="form-control" rows="7" v-model="selectedCustomer.shippingInstructions">
                                  </textarea>
                                </div>
                              </div>
                            </div> <!-- end row -->
                            <h4 class="mt-2">Saved Address</h4>

                            <p class="text-muted mb-3">Fill the form below in order to
                              send you the order's invoice.</p>

                            <div class="row">
                              <div class="col-md-4" v-for="address in selectedCustomer.venue.Addresses" v-bind:key="address.addressType">
                                <div class="border p-3 rounded mb-3 mb-md-0">
                                  <address class="mb-0 font-14 address-lg">
                                    <div class="custom-control custom-radio">
                                      <input type="radio" :id="'customRadio' + address.addressType" v-model="selectedCustomer.addressType" v-bind:value="address.addressType" class="custom-control-input">
                                      <label class="custom-control-label font-16" :for="'customRadio' + address.addressType">
                                        <span v-show="address.addressLine1">{{ address.addressLine1 }} <br></span>
                                        <span v-show="address.addressLine2">{{ address.addressLine2 }} <br></span>
                                        <span v-show="address.addressLine3">{{ address.addressLine3 }} <br></span>
                                        <span v-show="address.addressLine4">{{ address.addressLine4 }} <br></span>
                                        {{ address.city }}, {{ address.region }} {{ address.postalCode }}, {{ address.country }}<br>
                                    
                                      </label>
                                    </div>
                                  </address>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="col-lg-4">
                          <div class="border p-3 mt-4 mt-lg-0 rounded">
                            <h4 class="header-title mb-3">Order Summary</h4>
                            <div class="table-responsive">
                              <table class="table table-centered mb-0">
                                <tbody v-if="apiResponse.loading == false">
                                  <tr v-for="item in cart.Products" v-bind:key="item.Id + '-' + item.VariantId">
                                    <td>
                                      <img v-bind:src="item.Image" v-bind:alt="item.Name" v-bind:title="item.Name" class="rounded mr-2" height="48" />
                                      <p class="m-0 d-inline-block align-middle">
                                        <router-link class="text-body font-weight-semibold" :to="'/product/' + item.ProductId + '/' + item.VariantId">
                                          {{ item.Name }} <small>{{ item.Unit }}</small>
                                        </router-link>
                                        <br>
                                        <small>{{ item.Quantity }} x ${{ item.Price }}</small>
                                      </p>
                                    </td>
                                    <td class="text-right">
                                      ${{ Number(item.Price * item.Quantity).toFixed(2) }}
                                    </td>
                                  </tr>
                                  <tr class="text-right">
                                    <td>
                                      <h6 class="m-0">Sub Total:</h6>
                                    </td>
                                    <td class="text-right">
                                      ${{ Number(invoicePricing.Subtotal).toFixed(2) }}
                                    </td>
                                  </tr>
                                  <tr class="text-right" v-if="cart.Price.Discount">
                                    <td>
                                      <h6 class="m-0">Discount:</h6>
                                    </td>
                                    <td class="text-right">
                                      ${{ Number(invoicePricing.Discount).toFixed(2) }}
                                    </td>
                                  </tr>
                                  <tr class="text-right" v-show="customerShippingFee">
                                    <td>
                                      <h6 class="m-0">Shipping:</h6>
                                    </td>
                                    <td class="text-right">
                                      ${{ Number(customerShippingFee).toFixed(2) }}
                                    </td>
                                  </tr>
                                  <tr class="text-right">
                                    <td>
                                      <h6 class="m-0">GST:</h6>
                                    </td>
                                    <td class="text-right">
                                      ${{ Number(invoicePricing.Gst).toFixed(2) }}
                                    </td>
                                  </tr>
                                  <tr class="text-right">
                                    <td>
                                      <h5 class="m-0">Total:</h5>
                                    </td>
                                    <td class="text-right font-weight-semibold">
                                      ${{ Number(invoicePricing.Total).toFixed(2) }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <!-- end table-responsive -->
                          </div> <!-- end .border-->
                        </div> <!-- end col -->     
                      </div> <!-- end row-->
                      <div class="row" v-show="selectedCustomerCompleted && selectedCustomer.addressType !== null">
                        <div class="col-lg-12">
                          <form v-on:submit.prevent>
                            <h4 class="mt-4">Shipping Method</h4>
                            <div class="row d-flex justify-content-center" v-show="loadingShipping">
                              <div role="status" class="spinner-border avatar-lg text-primary" v-show="loadingShipping"></div>
                            </div>
                            <div class="row" v-show="!loadingShipping && !apiResponse.shippingError">
                              <div class="col-md-6">
                                <!-- Single Date Picker -->
                                <div class="form-group" v-show="shippingProvider == 'shippit'">
                                  <label>Shipping Date</label>
                                  <input type="text" class="form-control" data-provide="datepicker" v-model="shippingDate" data-date-format="yyyy/mm/dd">
                                </div>
                                <div class="form-group" v-show="shippingProvider == 'becoolcouriers'">
                                  <label>Shipping Date</label>
                                  <select class="form-control select3" data-toggle="select3" v-model="becoolSelectedWindow">
                                    <option v-for="item in becoolWindows.windows" v-bind:key="item.window_index" v-bind:value="item.window_index">
                                      {{ item.day }} {{ item.date }} {{ item.time_start }}-{{ item.time_end }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="custom-control custom-switch">
                                  <input type="checkbox" class="custom-control-input" id="shippingFreeCheckbox" v-model="shippingFree">
                                  <label class="custom-control-label" for="shippingFreeCheckbox">Free Shipping</label>
                                </div>
                              </div>
                            </div>
                            <div class="row" v-show="apiResponse.shippingError && !loadingShipping">
                              <div class="alert alert-danger" role="alert">
                                <h4 class="alert-heading">Shipping Error!</h4>
                                <p>There was an unexpected error when loading shipping estimate data. Click the button below to try again.</p>
                                <p class="mb-0">
                                  <button type="button" class="btn btn-info" v-on:click="getShippingQuotes">
                                    <i class="mdi mdi-refresh mr-1"></i> Retry
                                  </button>
                                </p>
                              </div>
                            </div>
                            <div class="row" v-show="!loadingShipping && !apiResponse.shippingError">
                              <div class="col-md-4" v-for="item in shippingQuotes" v-bind:key="item.courier + '-' + item.service">
                                <div class="border p-3 rounded mb-3 mb-md-0">
                                  <div class="custom-control custom-radio">
                                    <input type="radio" v-bind:id="'shippingMethodRadio' + item.courier + '-' + item.service" v-model="shippingType" v-bind:value="item.courier + '-' + item.service" class="custom-control-input">
                                    <label class="custom-control-label font-16 font-weight-bold" v-bind:for="'shippingMethodRadio' + item.courier + '-' + item.service">
                                      {{ item.courier }} {{ capitalizeFirstLetter(item.service) }} Delivery - ${{ item.price }}
                                    </label>
                                  </div>
                                  <p class="mb-0 pl-3 pt-1">Estimated {{ item.estimated_time }} shipping</p>
                                </div>
                              </div>
                            </div>
                            <!-- end row-->

                            <div class="row mt-4">
                              <div class="col-sm-6">
                                <router-link :to="'/cart'" class="btn text-muted d-none d-sm-inline-block btn-link font-weight-semibold">
                                  <i class="mdi mdi-arrow-left"></i> Back to Shopping Cart 
                                </router-link>
                              </div> <!-- end col -->
                              <div class="col-sm-6" v-show="customerShippingFee !== null">
                                <div class="text-sm-right">
                                  <button class="btn btn-danger" v-on:click="processOrder">
                                    <i class="mdi mdi-cash-multiple mr-1"></i> Complete Order
                                  </button>
                                </div>
                              </div> <!-- end col -->
                            </div> <!-- end row -->
                          </form>
                        </div>
                      </div> <!-- end row-->
                    </div>
                    <!-- End Shipping Information Content-->
                  </div> <!-- end tab content-->

                </div> <!-- end card-body-->
              </div> <!-- end card-->
            </div> <!-- end col -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require('axios').default;
const cart_api_url = '/api/client/cart/';
const venues_api_url = '/api/admin/customer/venue';
const venue_api_url = '/api/admin/customer/venue/';
const shipping_api_url = '/api/client/shipping/';
const order_confirm_api_url = '/api/admin/cart/confirm'

export default {
  data() {
    return {
      apiResponse: {
        loading: true,
        error: false,
        shippingError: false,
      },
      dayOrder: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
      processingOrder: false,
      selectedCustomer: {
        // Data that interacts with the form
        venueId: null,
        emailAddress: null,
        venueContacts: [],
        // Data finalised by form interactions
        shippingInstructions: "",
        addressType: null,
        venue: {
          Addresses: [],
        },
        venueContact: {
          firstName: null,
          lastName: null,
          emailAddress: null,
          phoneNumber: null,
        },
      },
      phones: [],
      loadingShipping: false,
      shippingFree: false,
      shippingType: null,
      shippingDate: this.getCurrentDate(),
      shippingQuotes: [],
      shippingProvider: null,
      becoolWindows: {
        windows: []
      },
      becoolSelectedWindow: null,
      venues: [],
      cart: {
        Products: [],
        Price: {
        }
      },
      orderSubmissionErrors: []
    }
  },
  watch: {
    "shippingType": {
      handler: function (val, oldVal) {
        if(val == null || oldVal == val) {
          return;
        }
        for(let quote of this.shippingQuotes) {
          let quoteId = quote.courier + '-' + quote.service;
          if(val == quoteId) {
            this.cart.Price['Shipping'] = quote.price;
            this.shippingProvider = quote.shipmentType;
          }
        }
      }
    },
    "selectedCustomer.addressType": {
      handler: function (val, oldVal) {
        if(val != null && oldVal != val) {
          this.getShippingQuotes();
        }
      }
    },
    "selectedCustomer.venueId": {
      handler: function (val, oldVal) {
        this.selectedCustomer.emailAddress = null;
        if(val != null && oldVal != val) {
          axios
            .get(venue_api_url + this.selectedCustomer.venueId)
            .then(response => {
              let venue = response.data.Venue;
              this.selectedCustomer.venue = venue;

              this.phones = venue.Phones;
              this.selectedCustomer.venueContacts = venue.Contacts;

              this.selectedCustomer.emailAddress = this.selectedCustomer.venueContacts[0].Email;

              this.selectedCustomer.shippingInstructions = "";
              if('ShippingInstructions' in venue && typeof venue['ShippingInstructions'] == "string") {
                this.selectedCustomer.shippingInstructions = venue.ShippingInstructions;
                this.selectedCustomer.shippingInstructions += "\n\n";
              }
              if('OpeningTimes' in venue) {
                let rows = [];
                for(let day of this.dayOrder) {
                  let openingTime = '';
                  if(day in venue.OpeningTimes.formatted) {
                    openingTime = venue.OpeningTimes.formatted[day];
                  }
                  rows.push(day + ': ' + openingTime);
                }
                this.selectedCustomer.shippingInstructions += rows.join("\n");
              }
            });
        }
      },
    },
    "selectedCustomer.emailAddress": {
      handler: function (val, oldVal) {
        if(val == null) {
          return;
        }
        if(oldVal != val) {
          this.selectedCustomer.venueContact = {
            firstName: null,
            lastName: null,
            emailAddress: null,
            phoneNumber: null
          }
          for(let contact of this.selectedCustomer.venueContacts) {
            if(contact.Email == this.selectedCustomer.emailAddress) {
              let phoneNumber = null;
              if(this.phones.length > 0) {
                phoneNumber = this.phones[0];
              }
              this.selectedCustomer.venueContact = {
                firstName: contact.FirstName,
                lastName: contact.LastName,
                emailAddress: contact.Email,
                phoneNumber: phoneNumber
              }
              return;
            }
          }
        }
      },
    }
  },
  computed: {
    invoicePricing: function() {
      let pricing = {
        Subtotal: Number(this.cart.Price.Subtotal),
        Gst: Number(this.cart.Price.Gst),
        Discount: 0,
        Shipping: 0,
        Total: 0
      };

      if(!this.shippingFree && this.cart.Price.hasOwnProperty("Shipping") && this.cart.Price['Shipping'] > 0) {
        pricing.Shipping = Number(this.cart.Price['Shipping']);
      }
      if(pricing.Shipping > 0) {
        pricing.Gst += Number(pricing.Shipping / 10);
      }
      pricing.Total = Number(pricing.Subtotal) + Number(pricing.Shipping) + Number(pricing.Gst);
      return pricing;
    },
    selectedCustomerCompleted: function () {
      return this.selectedCustomer.emailAddress != null && this.selectedCustomer.venueId != null;
    },
    customerShippingFee: function() {
      if(this.shippingFree) {
        return 0;
      }
      if(this.cart.Price.hasOwnProperty("Shipping") && this.cart.Price['Shipping'] > 0) {
        return Number(this.cart.Price['Shipping']);
      }
      if(this.shippingType !== null) {
        return 0;
      }
      return null;
    }
  },
  methods: {
    getCurrentDate: function() {
      let d = new Date();
      return [d.getFullYear(), d.getMonth() + 1, d.getDate()].join('/');
    },
    processOrder: function() {
      // Ensure that once button gets clicked and we're waiting for API response
      // we don't attempt to confirm the order again
      // if(this.processingOrder) {
      //   return;
      // }
      this.processingOrder = true;
      let shippingAddress = null;
      for(let address of this.selectedCustomer.venue.Addresses) {
        if(this.selectedCustomer.addressType == address.addressType) {
          shippingAddress = address;
          break;
        }
      }
      let shippingQuote = null;
      for(let shipping of this.shippingQuotes) {
        if(this.shippingType == shipping.courier + '-' + shipping.service) {
          shippingQuote = shipping;
          break;
        }
      }
      let orderDetails = {
        venueId: this.selectedCustomer.venueId,
        firstName: this.selectedCustomer.venueContact.firstName,
        lastName: this.selectedCustomer.venueContact.lastName,
        emailAddress: this.selectedCustomer.venueContact.emailAddress,
        phoneNumber: this.selectedCustomer.venueContact.phoneNumber,
        shipping: {
          type: this.shippingProvider,
          address: shippingAddress,
          free: this.shippingFree,
          quote: shippingQuote,
          date: this.shippingDate,
        },
        cartItems: this.cart.Products,
        shippingInstructions: this.selectedCustomer.shippingInstructions
      }

      if(this.shippingProvider == "becoolcouriers") {
        for(let shippingWindow of this.becoolWindows.windows) {
          if(shippingWindow.window_index == this.becoolSelectedWindow) {
            orderDetails.shipping['window'] = shippingWindow;
          }
        }
      }
      axios
        .post(order_confirm_api_url, orderDetails)
        .then(response => {
          if(response.data.success) {
            localStorage.currumbin_cart_cache = 0;
            let orderId = response.data.orderId
            this.$router.push({
              name: 'admin.orders.view',
              params: {
                orderId
              }
            });
          }
          else {
            this.orderSubmissionErrors = response.data.errors;
          }
        });
    },
    capitalizeFirstLetter: function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getShippingQuotes: function() {
      let selectedAddress = null;

      for(let address of this.selectedCustomer.venue.Addresses) {
        if(this.selectedCustomer.addressType == address.addressType) {
          selectedAddress = address;
        }
      }
      if(selectedAddress == null) {
        return;
      }
      this.loadingShipping = true;
      axios
        .post(shipping_api_url, {
          products: this.cart.Products,
          venue: selectedAddress
        })
        .then(response => {
          this.shippingQuotes = response.data.quotes;
          this.loadingShipping = false;
          this.becoolWindows = response.data.becoolWindows;
          this.apiResponse.shippingError = false;
        })
        .catch(error => {
          if(error) {
            this.apiResponse.shippingError = true;
            this.loadingShipping = false;
          }
        });
    },
    fetchData: function() {
      this.apiResponse.loading = true;
      this.apiResponse.error = false;
      axios
        .get(cart_api_url)
        .then(response => {
          this.cart = response.data;
          if(this.cart.Count == 0 || this.cart.Errors.length > 0) {
            this.$router.push({ name: 'cart.list' });
          }

          this.$nextTick(() => {
            this.apiResponse.loading = false;
          });
        })
        .catch(error => {
          if(error) {
            this.apiResponse.error = true;
            this.apiResponse.loading = false;
          }
        });
      axios
        .get(venues_api_url)
        .then(response => {
          this.venues = response.data.Items;

          this.$nextTick(() => {
            this.apiResponse.loading = false;
          });
        })
        .catch(error => {
          if(error) {
            this.apiResponse.error = true;
            this.apiResponse.loading = false;
          }
        });
    }
  },
  mounted() {
    this.fetchData();
    $('[data-toggle="select2"]').select2().on("select2:select", (e) => {
      // v-model looks for
      //  - an event named "change"
      //  - a value with property path "$event.target.value"
      e.target.dispatchEvent(new Event('change', { target: e.target }));
    });
  }
}
</script>
