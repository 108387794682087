<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>
        
        <!-- Start Content-->
        <div class="container-fluid">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Your Cart</h4>
              </div>
            </div>
          </div>
          <!-- End Page Title --> 
          <!-- Start Loading / Errors -->
          <div class="d-flex justify-content-center" v-if="apiResponse.loading">
            <div class="spinner-border avatar-lg text-primary" role="status"></div>
          </div>
          <div class="row" v-else-if="apiResponse.error">
            <div class="col-12">
              <div class="card">
                <!-- Start Error Message -->
                <div class="card-body">
                  <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Api Error!</h4>
                    <p>There was an unexpected error when loading data from the API. Click the button below to try again.</p>
                    <p class="mb-0">
                      <button type="button" class="btn btn-info" v-on:click="fetchData">
                        <i class="mdi mdi-refresh mr-1"></i> Retry
                      </button>
                    </p>
                  </div>
                </div>
                <!-- End Error Message -->
              </div>
            </div>
          </div>
          <!-- End Loading / Errors -->
          <!-- Start Cart Page -->
          <div class="row" v-else>
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="table-responsive">
                        <table class="table table-borderless table-centered mb-0">
                          <thead class="thead-light">
                            <tr>
                              <th>Product</th>
                              <th>Unit</th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th>Total</th>
                              <th style="width: 50px;"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in cart.Products" v-bind:key="item.Id + '-' + item.VariantId">
                              <td>
                                <p class="m-0 d-inline-block align-middle font-16">
                                  <img v-bind:src="item.Image" v-bind:alt="item.Name" v-bind:title="item.Name" class="rounded mr-3" height="94" />
                                  <router-link class="text-body" :to="'/product/' + item.ProductId + '/' + item.VariantId">
                                    {{ item.Name }}
                                  </router-link>
                                </p>
                              </td>
                              <td>
                                {{ item.Unit }}
                              </td>
                              <td>
                                ${{ item.Price }}
                              </td>
                              <td>
                                <input type="number" min="1" v-model.lazy="item.Quantity" class="form-control form-control-lg" placeholder="Qty" style="width: 90px;">
                              </td>
                              <td>
                                ${{ item.Price * item.Quantity }}
                              </td>
                              <td>
                                <a @click="deleteCartItem(item.ProductId, item.VariantId)" class="action-icon">
                                  <i class="mdi mdi-delete"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div> <!-- end table-responsive-->

                      <!-- action buttons-->
                      <div class="row mt-4">
                        <div class="col-sm-6">
                          <router-link :to="'/products'" class="btn text-muted d-none d-sm-inline-block btn-link font-weight-semibold">
                            <i class="mdi mdi-arrow-left"></i> Continue Shopping 
                          </router-link>
                        </div> <!-- end col -->
                        <div class="col-sm-6">
                          <div class="text-sm-right">
                            <router-link v-if="cart.Products" :to="'/cart/confirm'" class="btn btn-danger">
                              <i class="mdi mdi-cart-plus mr-1"></i> Checkout 
                            </router-link>
                          </div>
                        </div> <!-- end col -->
                      </div> <!-- end row-->
                    </div>
                    <!-- end col -->

                    <div class="col-lg-4">
                      <div class="border p-3 mt-4 mt-lg-0 rounded">
                        <h4 class="header-title mb-3">Order Summary</h4>

                        <div class="table-responsive">
                          <table class="table mb-0">
                            <tbody>
                              <tr>
                                <td>Subtotal :</td>
                                <td>${{ cart.Price.Subtotal }}</td>
                              </tr>
                              <tr v-if="cart.Price.Discount">
                                <td>Discount : </td>
                                <td>-${{ cart.Price.Discount }}</td>
                              </tr>
                              <tr>
                                <td>Shipping Charge :</td>
                                <td>${{ cart.Price.Shipping }}</td>
                              </tr>
                              <tr>
                                <td>GST : </td>
                                <td>${{ cart.Price.Gst }}</td>
                              </tr>
                              <tr>
                                <th>Total :</th>
                                <th>${{ cart.Price.Total }}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                    </div> <!-- end col -->
                  </div> <!-- end row -->
                </div> <!-- end card-body-->
              </div> <!-- end card-->
            </div> <!-- end col -->
          </div>
          <!-- End Cart Page -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
const api_url = '/api/client/cart/';
export default {
  data() {
    return {
      apiResponse: {
        loading: true,
        error: false,
      },
      pauseWatcher: true,
      cart: {
        Products: [],
        Price: {
        }
      },
    }
  },
  watch: {
    cart: {
      handler: function (val, oldVal) {
        if(oldVal != null && this.pauseWatcher == false) {
          this.pauseWatcher = true;
          axios
            .post(api_url, {
              Products: val.Products,
            })
            .then(response => {
              this.cart = response.data;
              localStorage.currumbin_cart_cache = response.data.Count;
              this.$nextTick(() => {
                this.pauseWatcher = false;
              });
            });
        }
      },
      deep: true
    }
  },
  methods: {
    fetchData: function() {
      this.pauseWatcher = true;
      this.apiResponse.loading = true;
      this.apiResponse.error = false;
    
      axios
        .get(api_url)
        .then(response => {
          this.cart = response.data;
          this.$nextTick(() => {
            this.pauseWatcher = false;
          });
        })
        .catch(error => {
          if (error) {
            this.apiResponse.error = true;
            this.apiResponse.loading = false;
          }
        });
    },
    deleteCartItem: function(productId, variantId) {
      this.pauseWatcher = true;
      axios
        .delete(api_url, {
          data: {
            ProductId: productId,
            VariantId: variantId,
          }
        })
        .then(response => {
          this.cart = response.data
          localStorage.currumbin_cart_cache = response.data.Count;
          this.$nextTick(() => {
            this.pauseWatcher = false;
          });
        });
    }
  },
  mounted() {
    // Send POST request
    this.fetchData();
  }
}
</script>
