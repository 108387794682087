import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

// Home Route components.
import routeHomeComponent from './views/home.vue';
import routeApplicationComponent from './views/application.vue';
import routeLoginComponent from './views/auth/login.vue';
import routeRegisterComponent from './views/auth/register.vue';
import routeLogoutComponent from './views/auth/logout.vue';
import route404Component from './views/errors/404.vue';

import routeAccountComponent from './views/account.vue';

// Client Routes
import routeProductsListComponent from './views/client/products/list.vue';
import routeProductViewComponent from './views/client/products/product.vue';
import routeOrdersListComponent from './views/client/orders/list.vue'
import routeOrderViewComponent from './views/client/orders/order.vue';
import routeCartListComponent from './views/client/cart/list.vue';
import routeCartConfirmComponent from './views/client/cart/confirm.vue';

// Admin Routes
import routeAdminApplicationsListComponent from './views/admin/applications/list.vue';
import routeAdminApplicationViewComponent from './views/admin/applications/view.vue';
import routeAdminCartListComponent from './views/admin/cart/list.vue';
import routeAdminCartConfirmComponent from './views/admin/cart/confirm.vue';
import routeAdminOrderListComponent from './views/admin/orders/list.vue';
import routeAdminOrderViewComponent from './views/admin/orders/order.vue';
import routeAdminClientsListViewComponent from './views/admin/clients/list.vue';
import routeAdminClientsViewComponent from './views/admin/clients/view.vue';

// Define some routes
let routes = [
  { name: "home", path: '/', component: routeHomeComponent },
  { name: "auth.login", path: '/auth/login', component: routeLoginComponent },
  { name: "auth.register", path: '/auth/register', component: routeRegisterComponent },
  { name: "auth.logout", path: '/auth/logout', component: routeLogoutComponent },
  { name: "application", path: '/application', component: routeApplicationComponent },
  { name: "account", path: '/account', component: routeAccountComponent },
];

// Add client routes
routes = routes.concat([
  { name: "products.list", path: '/products/', component: routeProductsListComponent },
  { name: "products.view", path: '/product/:productId/:variantId', component: routeProductViewComponent, props: true },
  { name: "orders.list", path: '/orders/', component: routeOrdersListComponent },
  { name: "orders.view", path: '/order/:orderId', component: routeOrderViewComponent, props: true },
  { name: "cart.list", path: '/cart/', component: routeCartListComponent },
  { name: "cart.confirm", path: '/cart/confirm/', component: routeCartConfirmComponent },
]);

// Add Admin Routes
routes = routes.concat([
  { name: "admin.applications.list", path: '/admin/applications/', component: routeAdminApplicationsListComponent },
  { name: "admin.applications.view", path: '/admin/application/:applicationId', component: routeAdminApplicationViewComponent, props: true },
  { name: "admin.cart.list", path: '/admin/cart/', component: routeAdminCartListComponent },
  { name: "admin.cart.confirm", path: '/admin/cart/confirm/', component: routeAdminCartConfirmComponent },
  { name: "admin.orders.list", path: '/admin/orders/', component: routeAdminOrderListComponent },
  { name: "admin.orders.view", path: '/admin/order/:orderId', component: routeAdminOrderViewComponent, props: true },
  { name: "admin.clients.list", path: '/admin/clients/', component: routeAdminClientsListViewComponent },
  { name: "admin.clients.view", path: '/admin/clients/:clientId', component: routeAdminClientsViewComponent, props: true },
]);

// Add our 404 error route as a catchall
routes.push({ name: "errors.404", "path": '*', component: route404Component });

// Create our frontend router
export default new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
});
