/* eslint-env jquery */

<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>
        <!-- Start Content-->
        <div class="container-fluid">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Products List</h4>
              </div>
            </div>
          </div>
          <!-- End Page Title --> 
          <!-- Start Products Page -->
          <div class="d-flex justify-content-center" v-if="apiResponse.loading">
            <div class="spinner-border avatar-lg text-primary" role="status"></div>
          </div>
          <div class="row" v-else-if="apiResponse.error">
            <div class="col-12">
              <div class="card">
                <!-- Start Error Message -->
                <div class="card-body">
                  <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Api Error!</h4>
                    <p>There was an unexpected error when loading data from the API. Click the button below to try again.</p>
                    <p class="mb-0">
                      <button type="button" class="btn btn-info" v-on:click="fetchData">
                        <i class="mdi mdi-refresh mr-1"></i> Retry
                      </button>
                    </p>
                  </div>
                </div>
                <!-- End Error Message -->
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-centered w-100 dt-responsive nowrap" id="products-datatable">
                      <thead class="thead-light">
                        <tr>
                          <th class="all">Product</th>
                          <th>Category</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Cart</th>
                        </tr>
                      </thead>
                      <tbody v-if="apiResponse.loading == false">
                        <tr v-show="products.Products.length == 0">
                          <td colspan="5">
                            <div class="d-flex justify-content-center">
                              <div class="spinner-border avatar-lg text-primary" role="status" v-show="products.Products.length == 0"></div>
                            </div>
                          </td>
                        </tr>
                        <tr v-for="item in products.Products" v-bind:key="item.Id + '-' + item.VariantId">
                          <td>
                            <p class="m-0 d-inline-block align-middle font-16">
                              <router-link class="text-body" :to="'/product/' + item.Id + '/' + item.VariantId">
                                <img v-bind:src="item.Image" v-bind:alt="item.Name" v-bind:title="item.Name" class="rounded mr-3" height="148" />
                                {{ item.Name }}
                              </router-link>
                            </p>
                          </td>
                          <td>
                            {{ item.Category }}
                          </td>
                          <td>
                            {{ item.Quantity }}
                          </td>
                          <td v-if="item.ComingSoon">
                            <i class="mdi mdi-circle text-danger"></i> Coming Soon
                          </td>
                          <td v-else>
                            ${{ Number(item.Price).toFixed(2) }}
                          </td>
                          <td v-if="item.InStock && !item.ComingSoon" class="table-action">
                            <form v-on:submit.prevent="addToCart(item)" class="form-group">
                              <div class="input-group">
                                <input class="form-control form-control-lg" v-model="productQuantities[item.Id+'-'+item.VariantId]" type="number" min="0" value="0">
                                <div class="input-group-append">
                                  <button class="btn btn-success btn-lg"> 
                                    <i class="mdi mdi-cart-plus"></i>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </td>
                          <td v-else-if="item.ComingSoon">
                            <i class="mdi mdi-circle text-danger"></i> Coming Soon
                          </td>
                          <td v-else>
                            <i class="mdi mdi-circle text-danger"></i> No Stock
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> <!-- end card-body-->
              </div> <!-- end card-->
            </div> <!-- end col -->
          </div>
          <!-- End Product Page -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
export default {
  data() {
    return {
      apiResponse: {
        loading: true,
        error: false
      },
      productQuantities: [],
      products: {
        Products: [],
      },
    }
  },
  methods: {
    fetchData: function() {
      var api_url = '/api/client/product';
      this.apiResponse.loading = true;
      this.apiResponse.error = false;
      // Send POST request
      axios
        .get(api_url)
        .then(response => {
          this.apiResponse.loading = false;
          this.products = response.data
          for(let item of this.products.Products) {
            this.productQuantities[item.Id + '-' + item.VariantId] = 0;
          }
        })
        .catch(error => {
          if(error) {
            this.apiResponse.error = true;
            this.apiResponse.loading = false;
          }
        });
    },
    addToCart: function (item) {
      let product_key = item.Id + '-' + item.VariantId;
      if(product_key in this.productQuantities && this.productQuantities[product_key] > 0) {
        // Send POST request
        var api_url = '/api/client/cart';
        axios
          .put(api_url, {
            Image: item.Image,
            ProductId: item.Id,
            VariantId: item.VariantId,
            SKU: item.SKU,
            Unit: item.Quantity,
            Quantity: this.productQuantities[product_key],
            Price: item.Price,
            Name: item.Name
          })
          .then(response => {
            $.NotificationApp.send(
              "Success!",
              "You have added " + item.Name + " (" + item.Quantity + ") into your cart for a total of " + response.data.Count + " items.",
              "top-center", "rgb(0,0,0)",
              "success"
            );
            localStorage.currumbin_cart_cache = response.data.Count;
            this.productQuantities[product_key] = 0;
          });
      }
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>
