<template>
<div class="navbar-custom">
  <ul class="list-unstyled topbar-right-menu float-right mb-0">
    <li class="dropdown notification-list">
      <a class="nav-link dropdown-toggle nav-user arrow-none mr-0" data-toggle="dropdown" id="topbar-userdrop" href="#" role="button" aria-haspopup="true"
        aria-expanded="false">
        <span class="account-user-avatar"> 
          <img :src="gravatar + '?d=identicon'" alt="user-image" class="rounded-circle">
        </span>
        <span>
          <span class="account-user-name">{{ userData.firstName }} {{ userData.lastName }}</span>
          <span class="account-position">{{ userRole }}</span>
        </span>
      </a>
      <div class="dropdown-menu dropdown-menu-right dropdown-menu-animated topbar-dropdown-menu profile-dropdown" aria-labelledby="topbar-userdrop">
        <!-- item-->
        <div class=" dropdown-header noti-title">
          <h6 class="text-overflow m-0">Welcome !</h6>
        </div>

        <!-- item-->
        <a href="/account" class="dropdown-item notify-item">
          <i class="mdi mdi-account-circle mr-1"></i>
          <span>My Account</span>
        </a>

        <!-- item-->
        <a href="/auth/logout" class="dropdown-item notify-item">
          <i class="mdi mdi-logout mr-1"></i>
          <span>Logout</span>
        </a>

      </div>
    </li>

  </ul>
  <button class="button-menu-mobile open-left disable-btn">
    <i class="mdi mdi-menu"></i>
  </button>
</div>
</template>
<script>
export default {
  data() {
    return {
      userData: {
        firstName: "",
        lastName: ""
      },
      userRole: "Customer",
      gravatar: ''
    }
  },
  mounted() {
    // Admin routes variable determines whether logged in user is an admin or not
    // to display the admin specific pages
    if (localStorage.getItem('currumbin_admin')) {
      this.userRole = "Administrator";
    }

    this.userData = JSON.parse(localStorage.getItem('currumbin_userdata'));
    this.gravatar = localStorage.getItem('currumbin_gravatar')
  }
}
</script>
