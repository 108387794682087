import Vue from 'vue';
import VueTitleComponent from './components/title.vue';
import MainHeaderComponent from './components/header.vue';
import SideNavbarComponent from './components/sidebar.vue';
import router from "./router";
import axios from 'axios';
import { ClientTable } from 'vue-tables-2';

Vue.component('vue-title', VueTitleComponent);
Vue.component('main-header', MainHeaderComponent);
Vue.component('side-navbar', SideNavbarComponent);
Vue.use(ClientTable, {}, false, 'bootstrap4');

// Global Router Guards
router.beforeEach((to, from, next) => {
  // Check whether the route is a path to an auth URL
  var isLoginPath = to.name == 'auth.login' || to.name == "auth.register" || to.name == "application";
  // Check if our JWT has been set and navigating to an auth URL
  if (localStorage.hasOwnProperty("currumbin_jwt") && isLoginPath) { 
    next({ name: 'home' });
  }
  // Check whether our JWT isn't set and we're navigating to a non-auth URL
  else if (!localStorage.hasOwnProperty("currumbin_jwt") && !isLoginPath) {
    localStorage.setItem('last_page', to.name);
    next({ name: 'auth.login' });
  }
  // Check for any admin page redirects
  else if (localStorage.hasOwnProperty("currumbin_admin")) {
    if(localStorage.getItem("currumbin_admin") && to.name.startsWith("cart")) {
      next({ name: 'admin.' + to.name });
    }
    else {
      next();
    }
  }
  else {
    next();
  }
});

// Global axios hook to send JWT if we have one
axios.interceptors.request.use(function (config) {
  if(localStorage.hasOwnProperty("currumbin_jwt")) {
    config.headers.Authorization = 'Bearer ' + localStorage.currumbin_jwt;
  }

  return config;
});

// Global axios hook to intercept 401 errors
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    // Redirect clients making any unauthorized requests to the admin APIs
    // to the home page
    if(String(router.name).startsWith("admin.")) {
      router.push({ name: 'home' });
    }
    // From here, we can assume that the user is logged out and should be redirected to the login page
    else {
      localStorage.clear();
      // localStorage.setItem('last_page', router.name);
      router.push({ name: 'auth.login' });
    }
  }
  else if (403 === error.response.status) {
    if(String(router.name).startsWith("admin.")) {
      localStorage.removeItem("currumbin_admin");
      router.push({ name: 'home' });
    }
    else {
      return Promise.reject(error);
    }
  }
  else {
    return Promise.reject(error);
  }
});

// Create and mount the root Vue instance.
new Vue({
  router
}).$mount('#app');
