/* eslint-env jquery */

<template>
  <div class="account-pages mt-5 mb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="card">
            <!-- Logo-->
            <div class="card-header pt-4 pb-4 text-center bg-dark">
              <a href="index.html">
                <span><img src="/assets/images/logo-wide.svg" alt="" height="40"></span>
              </a>
            </div>

            <div class="card-body p-4">
              
              <div class="text-center w-75 m-auto">
                <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Wholesale Application</h4>
                <p class="text-muted mb-4" v-if="applicationCompleted == false">
                  Already a wholesale customer?
                  <router-link :to="'/auth/register'" class="text-muted ml-1"><b>Create your account</b></router-link>, it takes less than a minute.  
                </p>
              </div>

              <form action="#" v-on:submit.prevent="registerFormSubmit" v-if="applicationCompleted == false">

                <h5 class="text-dark-30 mt-0">Business Details</h5>
                <div class="form-group license">
                  <label for="venueName">Venue Name #</label>
                  <input class="form-control" type="text" id="venueName" v-model="formData.venueName" placeholder="Example Brewing Co" required>
                </div>

                <div class="form-group license">
                  <label for="abn">ABN</label>
                  <input class="form-control" type="text" id="abn" v-model="formData.abn" v-on:blur="handleAbnUnfocus" placeholder="ABN" required>
                </div>

                <div class="form-group license">
                  <label for="license">Liquor License #</label>
                  <input class="form-control" type="text" id="license" v-model="formData.license" v-on:blur="handleLicenseUnfocus" placeholder="Enter your liquor license #" required>
                </div>

                <hr />

                <h5 class="text-dark-30 mt-0">Address Details</h5>
                <div class="form-group venue">
                  <label for="venueAddress1">Venue Address (Line 1)</label><br/>
                  <input class="form-control" type="text" id="venueAddress1" v-model="formData.venueAddress.line1" placeholder="123 Example Road" required>
                </div>

                <div class="form-group venue">
                  <label for="venueAddress2">Venue Address (Line 2)</label><br/>
                  <input class="form-control" type="text" id="venueAddress2" v-model="formData.venueAddress.line2" placeholder="">
                </div>

                <div class="form-group venue">
                  <label for="venueAddressCity">Venue Address (City)</label><br/>
                  <input class="form-control" type="text" id="venueAddressCity" v-model="formData.venueAddress.city" placeholder="">
                </div>

                <div class="form-group venue">
                  <label for="venueAddressPostcode">Venue Address (Postcode)</label><br/>
                  <input class="form-control" type="text" id="venueAddressPostcode" v-on:blur="handleVenuePostcodeUnblur" v-model="formData.venueAddress.postcode" placeholder="2000" required>
                </div>

                <div class="form-group venue">
                  <label for="venueAddressState">Venue Address (State)</label><br/>
                  <select id="venueAddressState" class="custom-select" v-model="formData.venueAddress.state">
                    <option value="QLD">QLD</option>
                    <option value="NSW">NSW</option>
                    <option value="VIC">VIC</option>
                    <option value="WA">WA</option>
                    <option value="ACT">ACT</option>
                    <option value="TAS">TAS</option>
                  </select>
                </div>

                <div class="form-group custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="switch-address" v-model="venueAddressNotDelivery">
                  <label class="custom-control-label" for="switch-address">Different Delivery Address</label>
                </div>

                <div v-if="venueAddressNotDelivery">
                  <div class="form-group venue">
                    <label for="deliveryAddress1">Delivery Address (Line 1)</label><br/>
                    <input class="form-control" type="text" id="deliveryAddress1" v-model="formData.deliveryAddress.line1" placeholder="123 Example Road" required>
                  </div>

                  <div class="form-group venue">
                    <label for="deliveryAddres2">Delivery Address (Line 2)</label><br/>
                    <input class="form-control" type="text" id="deliveryAddres2" v-model="formData.deliveryAddress.line2" placeholder="">
                  </div>

                  <div class="form-group venue">
                    <label for="deliveryAddressCity">Delivery Address (City)</label><br/>
                    <input class="form-control" type="text" id="deliveryAddressCity" v-model="formData.deliveryAddress.city" placeholder="">
                  </div>

                  <div class="form-group venue">
                    <label for="deliveryAddressPostcode">Delivery Address (Postcode)</label><br/>
                    <input class="form-control" type="text" id="deliveryAddressPostcode" v-on:blur="handleDeliveryPostcodeUnblur"  v-model="formData.deliveryAddress.postcode" placeholder="2000" required>
                  </div>

                  <div class="form-group venue">
                    <label for="deliveryAddressState">Delivery Address (State)</label><br/>
                    <select id="deliveryAddressState" class="custom-select" v-model="formData.deliveryAddress.state">
                      <option value="QLD">QLD</option>
                      <option value="NSW">NSW</option>
                      <option value="VIC">VIC</option>
                      <option value="WA">WA</option>
                      <option value="ACT">ACT</option>
                      <option value="TAS">TAS</option>
                    </select>
                  </div>
                </div>

                <hr />

                <h5 class="text-dark-30 mt-0">Contact Details</h5>
                <div class="form-group email">
                  <label for="contactName">Contact Name</label>
                  <input class="form-control" type="text" id="contactName" v-model="formData.contactName" required placeholder="John Smith">
                </div>

                <div class="form-group email">
                  <label for="contactMobileNumber">Contact Mobile Number</label>
                  <input class="form-control" type="text" id="contactMobileNumber" v-model="formData.contactMobileNumber" required placeholder="+61 123 456 789">
                </div>

                <div class="form-group email">
                  <label for="contactEmail">Contact Email address</label>
                  <input class="form-control" type="email" id="contactEmail" v-model="formData.contactEmail" required placeholder="john.smith@example.com">
                </div>

                <div class="form-group email">
                  <label for="accRecName">Invoice / Accounts Receivable</label>
                  <input class="form-control" type="text" id="accRecName" v-model="formData.accRecName" required placeholder="John Smith">
                </div>

                <div class="form-group email">
                  <label for="accRecEmail">Invoice / Accounts Email</label>
                  <input class="form-control" type="email" id="accRecEmail" v-model="formData.accRecEmail" required placeholder="invoices@example.com">
                </div>

                <div class="form-group mb-0 text-center signup">
                  <button class="btn btn-primary"> Submit Application </button>
                </div>
              </form>
              <div v-else>
                <div class="col-12 text-center">
                  <p class="text-muted">
                    Your Application was successful.
                    We will reach out to you after reviewing your information and ready to move forward with the next steps.
                  </p>
                </div>
              </div>
            </div> <!-- end card-body -->
          </div>
          <!-- end card -->

          <div class="row mt-3">
            <div class="col-12 text-center">
              <p class="text-muted">Already have account? <router-link :to="'/auth/login'" class="text-muted ml-1"><b>Log In</b></router-link>.</p>
            </div> <!-- end col-->
          </div>
          <!-- end row -->

        </div> <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
  <!-- end page -->
</template>

<script>
const axios = require('axios').default;
const api_url = '/api/client/application/';
const postcode_api_url = '/api/client/application/postcode/';

export default {
  data() {
    return {
      formData: {
        // Business Details
        venueName: null,
        abn: null,
        license: null,
        venueAddress: {
          line1: "",
          line2: "",
          city: "",
          state: "QLD",
          postcode: ""
        },
        deliveryAddress: {
          line1: "",
          line2: "",
          city: "",
          state: "QLD",
          postcode: ""
        },
        // Venue Contact
        contactName: null,
        contactEmail: null,
        contactMobileNumber: null,
        // Accounts Receivable
        accRecName: null,
        accRecEmail: null,
      },
      venueAddressCities: [],
      deliveryAddressCities: [],
      venueAddressNotDelivery: false,
      applicationCompleted: false,
    }
  },
  methods: {
    handleAbnUnfocus: function() {
      // Remove all non-numeric characters from the ABN
      let abn = this.formData.abn;
      this.formData.abn = abn.replace(/\D/g,'');
    },
    handleLicenseUnfocus: function() {
      // Remove all non-numeric characters from the ABN
      let license = this.formData.license;
      this.formData.license = license.replace(/\s/g,'');
    },
    handleDeliveryPostcodeUnblur: function() {
      if(postcode == "") {
        return;
      }
      let currentCity = this.formData.deliveryAddress.city;
      let postcode = this.formData.deliveryAddress.postcode;
      axios.get(postcode_api_url + postcode).then(response => {
        let data = response.data;
        let suburbs = data.suburbs;
        if(suburbs.length > 0 && !suburbs.includes(currentCity)) {
          this.formData.deliveryAddress.city = suburbs[0];
        }
      });
    },
    handleVenuePostcodeUnblur: function() {
      if(postcode == "") {
        return;
      }
      let currentCity = this.formData.venueAddress.city;
      let postcode = this.formData.venueAddress.postcode;
      axios.get(postcode_api_url + postcode).then(response => {
        let data = response.data;
        let suburbs = data.suburbs;
        if(suburbs.length > 0 && !suburbs.includes(currentCity)) {
          this.formData.venueAddress.city = suburbs[0];
        }
      });
    },
    registerFormSubmit: function() {
      if(!this.venueAddressNotDelivery) {
        this.formData.deliveryAddress = this.formData.venueAddress;
      }
      axios.post(api_url, this.formData)
      .then(response => {
        let data = response.data;
        if(data.success) {
          this.applicationCompleted = true;
          
          $.NotificationApp.send(
            "Success!",
            "You have successfully registered for the Currumbin Wholesale Portal!",
            "top-center", "rgb(0,0,0)",
            "success"
          );
        }
        else {
          for (let key of Object.keys(data.error)) {
            $.NotificationApp.send(
              "Failure!",
              data.error[key],
              "top-center", "rgb(0,0,0)",
              "error"
            );
          }

        }
      });
      return false;
    },
  },
  mounted() {
  }
}
</script>
