/* eslint-env jquery */

<template>
  <div class="account-pages mt-5 mb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="card">
            <!-- Logo-->
            <div class="card-header pt-4 pb-4 text-center bg-dark">
              <a href="index.html">
                <span><img src="/assets/images/logo-wide.svg" alt="" height="40"></span>
              </a>
            </div>

            <div class="card-body p-4">
              
              <div class="text-center w-75 m-auto">
                <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Wholesale Portal Sign Up</h4>
                <p class="text-muted mb-4">Already a wholesale customer? Create your account, it takes less than a minute.</p>
              </div>

              <form action="#" v-on:submit.prevent="registerFormSubmit" v-if="registrationCompleted == false">

                <div class="form-group text-center spinner d-none">
                  <div class="spinner-border avatar-md text-secondary" role="status"></div>
                </div>

                <div class="form-group license">
                  <label for="license">Liquor License #</label>
                  <input class="form-control" type="text" id="license" v-model="license" placeholder="Enter your liquor license #" required>
                </div>

                <div class="form-group venue d-none">
                  <div class="dropdown">
                    <label for="license">Venue</label><br/>
                    <button class="btn btn-light dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Select your venue
                    </button>
                    <div class="dropdown-menu dropdown-menu-animated">
                      <a class="dropdown-item" href="#">Venue #1</a>
                      <a class="dropdown-item" href="#">Detached Bottleshop A</a>
                      <a class="dropdown-item" href="#">Detached Bottleshop B</a>
                    </div>
                  </div>
                </div>

                <div class="form-group email">
                  <label for="firstname">First Name</label>
                  <input class="form-control" type="text" id="firstname" v-model="firstName" required placeholder="John">
                </div>

                <div class="form-group email">
                  <label for="lastname">Last Name</label>
                  <input class="form-control" type="text" id="lastname" v-model="lastName" required placeholder="Smith">
                </div>

                <div class="form-group email">
                  <label for="emailaddress">Email address</label>
                  <input class="form-control" type="email" id="emailaddress" v-model="username" required placeholder="Enter your email address">
                </div>

                <div class="form-group password" v-if="showSteps > 1">
                  <label for="password">Password</label>
                  <div class="input-group input-group-merge">
                    <input type="password" id="password" class="form-control" v-model="password" placeholder="Enter your password">
                    <div class="input-group-append" data-password="false">
                      <div class="input-group-text">
                        <span class="password-eye"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group confirm-password" v-if="showSteps > 1">
                  <label for="password">Confirm Password</label>
                  <div class="input-group input-group-merge">
                    <input type="password" id="confirm-password" class="form-control" v-model="passwordconfirm" placeholder="Re-enter your password">
                    <div class="input-group-append" data-password="false">
                      <div class="input-group-text">
                        <span class="password-eye"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group terms" v-if="showSteps > 10">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="checkbox-signup" v-model="termsandconditions" >
                    <label class="custom-control-label" for="checkbox-signup">I accept <a href="#" class="text-muted">Terms and Conditions</a></label>
                  </div>
                </div>

                <div class="form-group mb-0 text-center signup" v-if="showSteps == 1">
                  <button class="btn btn-primary"> Find Account </button>
                </div>
                <div class="form-group mb-0 text-center signup" v-if="showSteps > 1">
                  <button class="btn btn-primary"> Register Account </button>
                </div>

              </form>
              <div v-else>
                <div class="col-12 text-center">
                  <p class="text-muted">Registration was successful. <router-link :to="'/auth/login'" class="text-muted ml-1"><b>Log In</b></router-link>.</p>
                </div>
              </div>
            </div> <!-- end card-body -->
          </div>
          <!-- end card -->

          <div class="row mt-3">
            <div class="col-12 text-center">
              <p class="text-muted">Already have account? <router-link :to="'/auth/login'" class="text-muted ml-1"><b>Log In</b></router-link>.</p>
            </div> <!-- end col-->
          </div>
          <!-- end row -->

        </div> <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
  <!-- end page -->
</template>

<script>
const axios = require('axios').default;
const api_url = '/api/client/auth/register/';

export default {
  data() {
    return {
      license: null,
      firstName: null,
      lastName: null,
      username: null,
      password: null,
      passwordconfirm: null,
      termsandconditions: false,
      showSteps: 1,
      registrationCompleted: false,
    }
  },
  methods: {
    registerFormSubmit: function() {
      if(this.showSteps == 1) {
        this.findAccount();
      }
      else if(this.showSteps == 2) {
        this.registerAccount();
      }
    },
    findAccount: function() {
      // Setup proper registration methods
      // Send POST request
      axios
        .post(api_url, {
          email: this.username,
          licenseNumber: this.license,
          action: "verify_license_email"
        })
        .then(response => {
          let data = response.data;
          if(data.success) {
            this.showSteps = 2;
            $.NotificationApp.send(
              "Success!",
              "We've found your details, please continue with the registration process!",
              "top-center", "rgb(0,0,0)",
              "success"
            );
          }
          else {
            $.NotificationApp.send(
              "Failure!",
              data.error,
              "top-center", "rgb(0,0,0)",
              "error"
            );
          }
        });
    },
    registerAccount: function() {
      if(this.password != this.passwordconfirm) {
        $.NotificationApp.send(
          "Failure!",
          "The 2 passwords you have provided are different",
          "top-center", "rgb(0,0,0)",
          "error"
        );
        return;
      }
      else if(this.password.length > 8) {
        $.NotificationApp.send(
          "Failure!",
          "Password length needs to be greater than 8",
          "top-center", "rgb(0,0,0)",
          "error"
        );
        return;
      }
      axios
        .post(api_url, {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.username,
          licenseNumber: this.license,
          password: this.password,
          passwordConfirm: this.passwordconfirm,
          action: "register"
        })
        .then(response => {
          let data = response.data;
          if(data.success) {
            this.registrationCompleted = true;
            
            $.NotificationApp.send(
              "Success!",
              "You have successfully registered for the Currumbin Wholesale Portal!",
              "top-center", "rgb(0,0,0)",
              "success"
            );
          }
          else {
            $.NotificationApp.send(
              "Failure!",
              data.error,
              "top-center", "rgb(0,0,0)",
              "error"
            );
          }
        });
    }
  },
  mounted() {
  }
}
</script>
