/* eslint-env jquery */
<template>
<!-- ========== Left Sidebar Start ========== -->
<div class="left-side-menu">

  <!-- LOGO -->
  <a href="/" class="logo text-center logo-light">
    <span class="logo-lg">
      <img src="/assets/images/logo-wide.svg" height="30">
    </span>
    <span class="logo-sm">
      <img src="/assets/images/logo-dark.svg" alt="" height="16">
    </span>
  </a>

  <!-- LOGO -->
  <a href="/" class="logo text-center logo-dark">
    <span class="logo-lg">
      <img src="/assets/images/logo-wide.svg" height="30">
    </span>
    <span class="logo-sm">
      <img src="/assets/images/logo-dark.svg" alt="" height="16">
    </span>
  </a>

  <div class="h-100" id="left-side-menu-container" data-simplebar>

    <!--- Sidemenu -->
    <ul class="metismenu side-nav">

      <li class="side-nav-title side-nav-item">Navigation</li>

      <li v-bind:class="[
        'side-nav-item',
        routeStartsWith('home') ? 'mm-active' : ''
      ]">
        <router-link :to="'/'" class='side-nav-link'>
          <i class="uil-dashboard"></i>
          <span> Dashboard </span>
        </router-link>
      </li>

      <li v-bind:class="[
        'side-nav-item',
        routeStartsWith('products.') ? 'mm-active' : ''
      ]">
        <router-link :to="'/products'" class='side-nav-link'>
          <i class="mdi mdi-beer-outline"></i>
          <span> Products </span>
        </router-link>
      </li>

      <!-- Client Specific Routes -->
      <li v-if="!adminRoutes" v-bind:class="[
        'side-nav-item',
        routeStartsWith('orders.') ? 'mm-active' : ''
      ]">
        <router-link :to="'/orders'" class='side-nav-link'>
          <i class="uil-box"></i>
          <span> Orders </span>
        </router-link>
      </li>
      
      <li v-if="!adminRoutes" v-bind:class="[
        'side-nav-item',
        routeStartsWith('cart.') ? 'mm-active' : ''
      ]">
        <router-link :to="'/cart'" class='side-nav-link'>
          <i class="uil-shopping-cart-alt"></i>
          <span> Cart </span>
          <span class="badge badge-info badge-pill float-right">
            {{ cartItemCache }}
          </span>
        </router-link>
      </li>
      <!-- End Client Specific Routes -->

      <!-- Admin Specific Routes -->
      <li v-if="adminRoutes" v-bind:class="[
        'side-nav-item',
        routeStartsWith('admin.clients') ? 'mm-active' : ''
      ]">
        <router-link :to="'/admin/clients'" class='side-nav-link'>
          <i class="uil-users-alt"></i>
          <span> Clients </span>
        </router-link>
      </li>

      <li v-if="adminRoutes" v-bind:class="[
        'side-nav-item',
        routeStartsWith('admin.orders') ? 'mm-active' : ''
      ]">
        <router-link :to="'/admin/orders'" class='side-nav-link'>
          <i class="uil-box"></i>
          <span> Orders </span>
        </router-link>
      </li>

      <li v-if="adminRoutes" v-bind:class="[
        'side-nav-item',
        routeStartsWith('admin.applications') ? 'mm-active' : ''
      ]">
        <router-link :to="'/admin/applications'" class='side-nav-link'>
          <i class="uil-box"></i>
          <span> Applications </span>
        </router-link>
      </li>

      <li v-if="adminRoutes" v-bind:class="[
        'side-nav-item',
        routeStartsWith('admin.cart') ? 'mm-active' : ''
      ]">
        <router-link :to="'/admin/cart'" class='side-nav-link'>
          <i class="uil-shopping-cart-alt"></i>
          <span> Cart </span>
          <h4 v-show="cartItemCache > 0" class="float-right" style="margin: 0">
            <span class="badge badge-light badge-pill"> 
              {{ cartItemCache }}
            </span>
          </h4>
        </router-link>
      </li>
      <!-- End Admin Specific Routes -->
    </ul>
    <!-- End Sidebar -->
    <div class="clearfix"></div>
  </div>
  <!-- Sidebar -left -->
</div>
<!-- Left Sidebar End -->
</template>
<script>
const axios = require('axios').default;
export default {
  data() {
    return {
      cartItemCache: 0,
      adminRoutes: false,
    }
  },
  methods: {
    routeStartsWith(url) {
      return String(this.$route.name).startsWith(url);
    },
    onStorageUpdate(event) {
      if (event.key === "currumbin_cart_cache") {
        this.cartItemCache = localStorage.currumbin_cart_cache;
      }
    },
    cartBadgeRefresh() {
      if(localStorage.getItem('currumbin_cart_cache')) {
        this.cartItemCache = localStorage.currumbin_cart_cache
      }
    }
  },
  mounted() {
    // Admin routes variable determines whether logged in user is an admin or not
    // to display the admin specific pages
    if (localStorage.getItem('currumbin_admin')) {
      this.adminRoutes = localStorage.getItem('currumbin_admin');
    }
    // Only load the local cart cache if the cache is empty AND the user is logged in (has a valid jwt token)
    if (!localStorage.getItem('currumbin_cart_cache') && localStorage.hasOwnProperty("currumbin_jwt")) {
      axios
        .get('/api/client/cart')
        .then(response => {
          this.cartItemCache = response.data.Count;
          localStorage.currumbin_cart_cache = response.data.Count;
        });
    }
    else {
      this.cartItemCache = localStorage.currumbin_cart_cache;
    }
    window.addEventListener("storage", this.onStorageUpdate);
    setInterval(this.cartBadgeRefresh, 1500);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageUpdate);
  }
}
</script>
