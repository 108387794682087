/* eslint-env jquery */

<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>
        
        <!-- Start Content-->
        <div class="container-fluid">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Clients List</h4>
              </div>
            </div>
          </div>
          <!-- End Page Title --> 
          <!-- Start Loading / Errors -->
          <div class="d-flex justify-content-center" v-if="apiResponse.loading">
            <div class="spinner-border avatar-lg text-primary" role="status"></div>
          </div>
          <div class="row" v-else-if="apiResponse.error">
            <div class="col-12">
              <div class="card">
                <!-- Start Error Message -->
                <div class="card-body">
                  <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Api Error!</h4>
                    <p>There was an unexpected error when loading data from the API. Click the button below to try again.</p>
                    <p class="mb-0">
                      <button type="button" class="btn btn-info" v-on:click="fetchData">
                        <i class="mdi mdi-refresh mr-1"></i> Retry
                      </button>
                    </p>
                  </div>
                </div>
                <!-- End Error Message -->
              </div>
            </div>
          </div>
          <!-- End Loading / Errors -->
          <!-- Start Clients Page -->
          <div class="row" v-else>
            <div class="col-12">
              <div v-if="apiResponse.loading == false">
                <div class="mb-2 mt-sm-0 mt-3 text-sm-right">
                  <button type="button" class="btn btn-info mr-1" v-on:click="triggerCacheRefresh">
                    <i class="mdi mdi-refresh mr-1"></i> Trigger Cache Refresh
                  </button>
                  <button type="button" class="btn btn-info" v-on:click="refreshLocalCache">
                    <i class="mdi mdi-refresh mr-1"></i> Refresh Local Cache
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div id="clientsTable" v-if="apiResponse.loading == false">
                    <v-client-table v-if="customers" :data="customers" :columns="columns" :options="options">
                      <router-link slot="Name" slot-scope="{row}" :to="'/admin/clients/' + row.Id " class="btn text-muted d-none d-sm-inline-block btn-link font-weight-semibold">
                        {{ row.Name }}
                      </router-link>
                      
                      <div slot="Action" slot-scope="{row}">
                        <router-link :to="'/admin/clients/' + row.Id " class="btn text-muted d-none d-sm-inline-block btn-link font-weight-semibold">
                          <button type="button" class="btn btn-info btn-sm"><i class="mdi mdi-eye mr-1"></i> <span>View</span> </button>
                        </router-link>
                      </div>
                    </v-client-table>
                  </div>
                </div> <!-- end card-body-->
              </div> <!-- end card-->
            </div> <!-- end col -->
          </div>
          <!-- End Product Page -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
const venues_api_url = '/api/admin/customer/venue';

export default {
  data() {
    return {
      apiResponse: {
        loading: true,
        error: false
      },
      columns: ['Name', 'OpeningTimes', 'Action'],
      customers: [],
      options: {
        filterable: ['Name', 'OpeningTimes'],
        column: 'Name',
        headings: {
          OpeningTimes: 'Opening Hours',
        },
        ascending: true
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData: function() {
      this.apiResponse.loading = true;
      this.apiResponse.error = false;

      axios.get(venues_api_url)
        .then(response => {
          this.customers = response.data.Items;
          this.apiResponse.loading = false;
        })
        .catch(error => {
          if (error) {
            this.apiResponse.error = true;
            this.apiResponse.loading = false;
          }
        });
    },
    async triggerCacheRefresh() {
      // Send POST request
      await axios.post("/api/admin/customer/trigger");

      $.NotificationApp.send(
        "Success!",
        "The cache background task has been triggered, this will generally take up to a minute to complete.",
        "top-center", "rgb(0,0,0)",
        "success"
      );
    },
    async refreshLocalCache() {
      // Send POST request
      this.apiResponse.loading;
      let { data } = await axios.get(venues_api_url + "?refresh=true");
      this.customers = data.Items;
      this.apiResponse.loading = false;
    }
  }
}
</script>
