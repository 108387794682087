<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>
        
        <!-- Start Content-->
        <div class="container-fluid">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Your Cart</h4>
              </div>
            </div>
          </div>
          <!-- End Page Title -->
          <div class="row" v-show="cart.Errors.length > 0">
            <div class="col-12">
              <div class="alert alert-danger" role="alert">
                <h4 class="alert-heading">Please fix these issues with your cart before continuing.</h4>
                <ul v-for="error in cart.Errors" v-bind:key="error">
                  <li>{{ error }}</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- Start Cart Page -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="table-responsive">
                        <table class="table table-borderless table-centered mb-0">
                          <thead class="thead-light">
                            <tr>
                              <th>Product</th>
                              <th>Unit</th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th>Total</th>
                              <th style="width: 50px;"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in cart.Products" v-bind:key="item.Id + '-' + item.VariantId">
                              <td>
                                <p class="m-0 d-inline-block align-middle font-16">
                                  <img v-bind:src="item.Image" v-bind:alt="item.Name" v-bind:title="item.Name" class="rounded mr-3" height="94" />
                                  <router-link class="text-body" :to="'/product/' + item.ProductId + '/' + item.VariantId">
                                    {{ item.Name }}
                                  </router-link>
                                </p>
                              </td>
                              <td>
                                {{ item.Unit }}
                              </td>
                              <td>
                                ${{ Number(item.Price).toFixed(2) }}
                              </td>
                              <td>
                                <input type="number" min="1" v-model.lazy="item.Quantity" class="form-control form-control-lg" placeholder="Qty" style="width: 90px;">
                              </td>
                              <td>
                                ${{ Number(item.Price * item.Quantity).toFixed(2) }}
                              </td>
                              <td>
                                <a @click="deleteCartItem(item.SKU)" class="action-icon">
                                  <i class="mdi mdi-delete"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div> <!-- end table-responsive-->

                      <!-- action buttons-->
                      <div class="row mt-4">
                        <div class="col-sm-6">
                          <router-link :to="'/products'" class="btn text-muted d-none d-sm-inline-block btn-link font-weight-semibold">
                            <i class="mdi mdi-arrow-left"></i> Continue Shopping 
                          </router-link>
                        </div> <!-- end col -->
                        <div class="col-sm-6" v-show="cart.Errors.length == 0 && cart.Products.length > 0">
                          <div class="text-sm-right">
                            <router-link v-if="cart.Products" :to="'/cart/confirm'" class="btn btn-danger">
                              <i class="mdi mdi-cart-plus mr-1"></i> Checkout 
                            </router-link>
                          </div>
                        </div> <!-- end col -->
                      </div> <!-- end row-->
                    </div>
                    <!-- end col -->

                    <div class="col-lg-4">
                      <div class="border p-3 mt-4 mt-lg-0 rounded">
                        <h4 class="header-title mb-3">Order Summary</h4>

                        <div class="table-responsive">
                          <table class="table mb-0">
                            <tbody>
                              <tr>
                                <td>Subtotal :</td>
                                <td>${{ Number(cart.Price.Subtotal).toFixed(2) }}</td>
                              </tr>
                              <tr v-if="cart.Price.Discount">
                                <td>Discount : </td>
                                <td>-${{ Number(cart.Price.Discount).toFixed(2) }}</td>
                              </tr>
                              <tr>
                                <td>GST : </td>
                                <td>${{ Number(cart.Price.Gst).toFixed(2) }}</td>
                              </tr>
                              <tr>
                                <th>Total :</th>
                                <th>${{ Number(cart.Price.Total).toFixed(2) }}</th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- end table-responsive -->
                      </div>
                    </div> <!-- end col -->
                  </div> <!-- end row -->
                </div> <!-- end card-body-->
              </div> <!-- end card-->
            </div> <!-- end col -->
          </div>
          <!-- End Cart Page -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
const api_url = '/api/client/cart/';
export default {
  data() {
    return {
      pauseWatcher: true,
      cart: {
        Errors: [],
        Products: [],
        Price: {
        }
      },
    }
  },
  watch: {
    cart: {
      handler: function (val, oldVal) {
        if(oldVal != null && this.pauseWatcher == false) {
          this.pauseWatcher = true;
          axios
            .post(api_url, {
              Products: val.Products,
            })
            .then(response => {
              this.cart = response.data;
              localStorage.currumbin_cart_cache = response.data.Count;
              this.$nextTick(() => {
                this.pauseWatcher = false;
              });
            });
        }
      },
      deep: true
    }
  },
  methods: {
    deleteCartItem: function(SKU) {
      this.pauseWatcher = true;
      axios
        .delete(api_url, {
          data: {
            SKU: SKU,
          }
        })
        .then(response => {
          this.cart = response.data
          localStorage.currumbin_cart_cache = response.data.Count;
          this.$nextTick(() => {
            this.pauseWatcher = false;
          });
        });
    }
  },
  mounted() {
    // Send POST request
    axios
      .get(api_url)
      .then(response => {
        this.cart = response.data
        this.$nextTick(() => {
          this.pauseWatcher = false;
        });
      });
  }
}
</script>
