<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>

        <!-- Start Content-->
        <div class="container-fluid" v-if="orderLoading">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Loading Order...</h4>
              </div>
            </div>
          </div>
        </div>
        <!-- Start Content-->
        <div class="container-fluid" v-else-if="!orderFound">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Order Not Found</h4>
              </div>
            </div>
          </div>
        </div>
        <!-- Start Content-->
        <div class="container-fluid" v-else>
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Tax Invoice - Order #{{ order.XeroInvoiceId }}</h4>
              </div>
            </div>
          </div>
          <!-- End Page Title -->
         <!-- Start Order Progress Bar -->
          <div class="row justify-content-center d-print-none">
            <div class="col-lg-7 col-md-10 col-sm-11">
              <div class="horizontal-steps mt-4 mb-4 pb-5">
                <div class="horizontal-steps-content">
                  <div class="step-item current">
                    <span>Order Placed</span>
                  </div>
                  <div class="step-item">
                    <span>Packed</span>
                  </div>
                  <div class="step-item">
                    <span>Shipped</span>
                  </div>
                  <div class="step-item">
                    <span>Delivered</span>
                  </div>
                </div>

                <div class="process-line" style="width: 0%;"></div>
              </div>
            </div>
          </div>
          <!-- End Order Progress Bar -->
          <!-- Start Invoice Portion -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">

                  <!-- Invoice Logo-->
                  <div class="clearfix">
                    <div class="float-left mb-3">
                      <img src="/assets/images/logo-dark.svg" alt="" height="64">
                    </div>
                    <div class="float-right">
                      <h4 class="m-0 d-print-none">Invoice {{ order.XeroInvoiceId }}</h4>
                    </div>
                  </div>

                  <!-- Invoice Detail-->
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="float-left mt-3">
                        <p><b>Hello {{ order.Customer.firstName }} {{ order.Customer.lastName }},</b></p>
                        <p class="text-muted font-13">
                          Please make payment within 14 days of this order, and do not hesitate to contact us with any questions.
                        </p>
                      </div>
                    </div><!-- end col -->
                    <div class="col-sm-4 offset-sm-2">
                      <div class="mt-3 float-sm-right">
                        <p class="font-13"><strong>Order Date: </strong> <span class="float-right">{{ order.Shipping.date }}</span></p>
                        <p class="font-13" v-if="order.ShippitTrackingId">
                          <strong>Shipping: </strong>
                          <span class="float-right">
                            {{ order.ShippitTrackingId }}
                          </span>
                        </p>
                        <!-- <p class="font-13"><strong>Order Status: </strong> <span class="badge badge-success float-right">Paid</span></p> -->
                        <p class="font-13" v-if="order.XeroInvoiceId"><strong>Order ID: </strong> <span class="float-right">{{ order.XeroInvoiceId }}</span></p>
                        <p class="font-13"><strong>Internal ID: </strong> <span class="float-right">{{ order.OrderId }}</span></p>
                      </div>
                    </div><!-- end col -->
                  </div>
                  <!-- end row -->
    
                  <div class="row mt-4">
                    <div class="col-sm-4">
                      <h6>Billing Address</h6>
                      <address>
                        {{ order.Customer.firstName }} {{ order.Customer.lastName }}<br>
                        {{ order.Shipping.address.addressLine1 }}<br>
                        {{ order.Shipping.address.city }}, {{ order.Shipping.address.region }}, {{ order.Shipping.address.country }}, {{ order.Shipping.address.postalCode }}<br>
                      </address>
                    </div> <!-- end col-->
    
                    <div class="col-sm-4">
                      <h6>Shipping Address</h6>
                      <address>
                        {{ order.Customer.firstName }} {{ order.Customer.lastName }}<br>
                        {{ order.Shipping.address.addressLine1 }}<br>
                        {{ order.Shipping.address.city }}, {{ order.Shipping.address.region }}, {{ order.Shipping.address.country }}, {{ order.Shipping.address.postalCode }}<br>
                      </address>
                    </div> <!-- end col-->
                  </div>  
                  <!-- end row -->    

                  <div class="row">
                    <div class="col-12">
                      <div class="table-responsive">
                        <table class="table mt-4">
                          <thead>
                            <tr>
                              <th class="all">Item</th>
                              <th>Quantity</th>
                              <th>Price</th>
                              <th class="text-right">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in order.Products" v-bind:key="item.ProductId + '-' + item.VariantId">
                              <td>
                                <p class="m-0 d-inline-block align-middle font-16">
                                  <router-link class="text-body" :to="'/product/' + item.ProductId + '/' + item.VariantId">
                                    <img v-bind:src="item.Image" v-bind:alt="item.Name" v-bind:title="item.Name" class="rounded mr-3" height="48" />
                                    {{ item.Name }}
                                  </router-link>
                                </p>
                              </td>
                              <td>{{ item.Quantity }}</td>
                              <td>${{ item.Price }}</td>
                              <td class="text-right">${{ Number(item.Quantity * item.Price).toFixed(2) }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div> <!-- end table-responsive-->
                    </div> <!-- end col -->
                  </div>
                  <!-- end row -->

                  <div class="row">
                    <div class="col-sm-6">
                      <div class="clearfix pt-3">
                        <h6 class="text-muted">Notes:</h6>
                        <small>
                          All accounts are to be paid within 14 days from receipt of
                          invoice. To be paid by cheque or credit card or direct payment
                          online.
                        </small>
                      </div>
                    </div> <!-- end col -->
                    <div class="col-sm-6">
                      <div class="float-right mt-3 mt-sm-0" style="min-width: 200px;">
                        <p><b>Sub-total:</b> <span class="float-right">${{ invoicePricing.subtotal }}</span></p>
                        <p v-if="order.Discount"><b>Discount:</b> <span class="float-right">-${{ invoicePricing.discount }}</span></p>
                        <p><b>Shipping:</b> <span class="float-right">${{ invoicePricing.shipping }}</span></p>
                        <p><b>GST:</b> <span class="float-right">${{ invoicePricing.gst }}</span></p>
                        <h3><b>Total:</b> <span class="float-right">${{ invoicePricing.total}}</span></h3>
                      </div>
                      <div class="clearfix"></div>
                    </div> <!-- end col -->
                  </div>
                  <!-- end row-->

                  <div class="d-print-none mt-4">
                    <div class="text-right">
                      <a href="javascript:window.print()" class="btn btn-primary"><i class="mdi mdi-printer"></i> Print</a>
                    </div>
                  </div>   
                  <!-- end buttons -->

                </div> <!-- end card-body-->
              </div> <!-- end card -->
            </div> <!-- end col-->
          </div>
          <!-- End Invoice Portion -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
const order_api_url = '/api/client/order/';

export default {
  props: [
    'orderId'
  ],
  data() {
    return {
      orderLoading: true,
      orderFound: false,
      order: {},
    }
  },
  computed: {
    invoicePricing: function() {
      let pricing = {
        subtotal: 0,
        shipping: 0,
        gst: 0,
        total: 0,
      }

      this.order.Products.forEach(item => {
        let price = item.Price * item.Quantity;
        pricing.subtotal += price;
      });

      pricing.shipping = this.order.Shipping.quote.price;

      pricing.subtotal = (pricing.subtotal + pricing.shipping).toFixed(2);
      pricing.shipping = pricing.shipping.toFixed(2);
      pricing.gst = this.calculateGst(pricing.subtotal).toFixed(2);
      pricing.total = (Number(pricing.subtotal) + Number(pricing.gst)).toFixed(2);
      return pricing;
    }
  },
  methods: {
    // A fix for rounding of xx.xx5 values rounding down than up when using toFixed(2)
    // e.g. 30.355 turns into 30.35 rather than 30.36
    round: function(price, places) {
      return +(Math.round(price + "e+" + places)  + "e-" + places);
    },
    calculateGst: function(price) {
      return this.round(price / 10, 2);
    }
  },
  mounted() {
    var api_url = order_api_url + this.orderId;
    // Send POST request
    axios
      .get(
        api_url
      )
      .then(response => {
        this.orderLoading = false;
        this.orderFound = true;
        this.order = response.data.order;
      })
      .catch(error => {
        if (error.response && error.response.status == 404) {
          this.orderFound = false;
          this.orderLoading = false;
        }
      });
  }
}
</script>
