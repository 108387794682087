/* eslint-env jquery */

<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>
        
        <!-- Start Content-->
        <div class="container-fluid">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">My Account</h4>
              </div>
            </div>
          </div>
          <!-- End Page Title --> 
          
          <div v-show="updateSuccess" class="alert alert-dismissible alert-success" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <strong>Success - </strong> You were successfully logged in!
          </div>
          <!-- Start Product Page -->
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <!-- Profile Picture -->
                  <div class="">
                    <h6 class="font-14">Avatar:</h6>
                    <a href="https://en.gravatar.com/" target="_blank">
                      <img v-bind:src="gravatar + '?d=identicon'" class="rounded mr-3" height="148" />
                      <button type="button" class="btn btn-light"><i class="mdi mdi-file-upload mr-1"></i> <span>Update</span> </button>
                    </a>
                  </div>
                  <form class="mt-4" v-on:submit.prevent="updateAccount">
                    <div class="form-group password">
                      <label for="password">Current Password</label>
                      <div class="input-group input-group-merge">
                        <input type="password" v-model="password" id="password" class="form-control" placeholder="Enter your current password">
                        <div class="input-group-append" data-password="false">
                          <div class="input-group-text">
                            <span class="password-eye"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group password">
                      <label for="newPassword">New Password</label>
                      <div class="input-group input-group-merge">
                        <input type="password" v-model="newPassword" id="newPassword" class="form-control" placeholder="Enter your new password">
                        <div class="input-group-append" data-password="false">
                          <div class="input-group-text">
                            <span class="password-eye"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group password">
                      <label for="newPasswordConfirm">Confirm New Password</label>
                      <div class="input-group input-group-merge">
                        <input type="password" v-model="newPasswordConfirm" id="newPasswordConfirm" class="form-control" placeholder="Confirm your new password">
                        <div class="input-group-append" data-password="false">
                          <div class="input-group-text">
                            <span class="password-eye"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                    <button class="btn btn-primary"><i class="mdi mdi-wrench mr-1"></i> <span>Save Changes</span> </button> 
                  </form>
                </div>
              </div> <!-- end card-->
            </div> <!-- end col-->
          </div>
          <!-- End Product Page -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
export default {
  data() {
    return {
      updateSuccess: false,
      errors: [],
      userData: {
        firstName: "",
        lastName: ""
      },
      userRole: "Customer",
      gravatar: '',
      password: '',
      newPassword: '',
      newPasswordConfirm: ''
    }
  },
  methods: {
    updateAccount: function() {
      if(this.newPassword.length == 0 && this.newPasswordConfirm == 0) {
        $.NotificationApp.send(
          "Warning!",
          "No changes were made to your account.",
          "top-center", "rgb(0,0,0)",
          "warning"
        );
        return;
      }
      else if(this.newPassword != this.newPasswordConfirm) {
        $.NotificationApp.send(
          "Failure!",
          "The new passwords did not match.",
          "top-center", "rgb(0,0,0)",
          "error"
        );
        return;
      }
      else if(this.newPassword.length < 8) {
        $.NotificationApp.send(
          "Failure!",
          "New password needs to be at least 8 characters in length.",
          "top-center", "rgb(0,0,0)",
          "error"
        );
        return;
      }
      var api_url = '/api/client/account/';
      // Send POST request
      axios
        .post(api_url, {
          password: this.password,
          newPassword: this.newPassword
        })
        .then(response => {
          if(response.data.success) {
            this.password = "";
            this.newPassword = "";
            this.newPasswordConfirm = "";
            this.updateSuccess = true;
            
            $.NotificationApp.send(
              "Success!",
              "Your password has been changed!",
              "top-center", "rgb(0,0,0)",
              "success"
            );
          }
          else {
            $.NotificationApp.send(
              "Failure!",
              response.data.error,
              "top-center", "rgb(0,0,0)",
              "error"
            );
          }
        });

    }
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('currumbin_userdata'));
    this.gravatar = localStorage.getItem('currumbin_gravatar');
  }
}
</script>
