/* eslint-env jquery */

<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>
        
        <!-- Start Content-->
        <div class="container-fluid">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Wholesale Applications List</h4>
              </div>
            </div>
          </div>
          <!-- End Page Title --> 
          <!-- Start Loading / Errors -->
          <div class="d-flex justify-content-center" v-if="apiResponse.loading">
            <div class="spinner-border avatar-lg text-primary" role="status"></div>
          </div>
          <div class="row" v-else-if="apiResponse.error">
            <div class="col-12">
              <div class="card">
                <!-- Start Error Message -->
                <div class="card-body">
                  <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Api Error!</h4>
                    <p>There was an unexpected error when loading data from the API. Click the button below to try again.</p>
                    <p class="mb-0">
                      <button type="button" class="btn btn-info" v-on:click="fetchData">
                        <i class="mdi mdi-refresh mr-1"></i> Retry
                      </button>
                    </p>
                  </div>
                </div>
                <!-- End Error Message -->
              </div>
            </div>
          </div>
          <!-- End Loading / Errors -->
          <!-- Start Applications Page -->
          <div class="row" v-else>
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-centered w-100 dt-responsive nowrap" id="products-datatable">
                      <thead class="thead-light">
                        <tr>
                          <th class="all">Application Id</th>
                          <th>Venue Name</th>
                          <th>Contact Name</th>
                          <th>Contact Email</th>
                          <th>Accounts Receivable</th>
                          <th>Accounts Receivable Email</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in applications" v-bind:key="item.applicationId">
                          <td>
                            <p>
                              <router-link class="text-body" :to="'/admin/application/' + item.applicationId + '/'">
                                {{ item.applicationId }}
                              </router-link>
                            </p>
                          </td>
                          <td>
                            {{ item.venueName }}
                          </td>
                          <td>
                            {{ item.contactName }}
                          </td>
                          <td>
                            {{ item.contactEmail }}
                          </td>
                          <td>
                            {{ item.accountsReceivable }}
                          </td>
                          <td>
                            {{ item.accountsReceivableEmail }}
                          </td>
                          <td>
                            <router-link class="btn btn-primary" :to="'/admin/application/' + item.applicationId + '/'">
                              View
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div> <!-- end card-body-->
              </div> <!-- end card-->
            </div> <!-- end col -->
          </div>
          <!-- End Product Page -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
const api_url = '/api/admin/application/';
export default {
  data() {
    return {
      apiResponse: {
        loading: true,
        error: false
      },
      applicationsLoaded: false,
      applications: [],
    }
  },
  methods: {
    fetchData: function() {
      this.apiResponse.loading = true;
      this.apiResponse.error = false;

      axios.get(api_url)
        .then(response => {
          this.applications = response.data.applications;
          this.apiResponse.loading = false;
        })
        .catch(error => {
          if (error) {
            this.apiResponse.error = true;
            this.apiResponse.loading = false;
          }
        });
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>
