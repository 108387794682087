<template>
  <div class="account-pages mt-5 mb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="card">
            <!-- Logo-->
            <div class="card-header pt-4 pb-4 text-center bg-dark">
              <a href="index.html">
                <span><img src="/assets/images/logo-wide.svg" alt="" height="40"></span>
              </a>
            </div>

            <div class="card-body p-4">
              
              <div class="text-center w-75 m-auto">
                <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Wholesale Portal</h4>
                <p class="text-muted mb-4">You've been logged out. Was this an accident?</p>
                <p class="text-muted mb-4"><router-link :to="'/auth/login'" class="text-muted ml-1"><b>click me to log back in</b></router-link>.</p>
              </div>
            </div> <!-- end card-body -->
          </div>
          <!-- end card -->
        </div> <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
  <!-- end page -->
</template>

<script>
export default {
  mounted() {
    localStorage.clear();
  }
}
</script>
