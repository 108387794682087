/* eslint-env jquery */
<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>

        <!-- Start Content-->
        <div class="container-fluid">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-sm-12">
              <!-- Profile -->
              <div class="card bg-primary">
                <div class="card-body profile-user-box">
                  <div class="row">
                    <div class="col-sm-8">
                      <div class="media">
                        <div class="media-body">
                          <h4 class="mt-1 mb-1 text-white">{{ venue.Name }}</h4>
                          <p class="font-13 text-white-50">
                            Liquor License: {{ venue.LicenseId }}
                          </p>

                          <ul class="mb-0 list-inline text-light">
                            <li class="list-inline-item mr-3">
                              <h5 class="mb-1">$ {{ totalRevenue }}</h5>
                              <p class="mb-0 font-13 text-white-50">
                                Total Revenue
                              </p>
                            </li>
                            <li class="list-inline-item">
                              <h5 class="mb-1">{{ orders.length }}</h5>
                              <p class="mb-0 font-13 text-white-50">
                                Number of Orders
                              </p>
                            </li>
                          </ul>
                        </div>
                        <!-- end media-body-->
                      </div>
                    </div>
                    <!-- end col-->

                    <div class="col-sm-4">
                      <div class="text-center mt-sm-0 mt-3 text-sm-right">
                        <button type="button" class="btn btn-light" v-on:click="saveChangesFunction">
                          <i class="mdi mdi-account-edit mr-1"></i> Save Changes
                        </button>
                      </div>
                    </div>
                    <!-- end col-->
                  </div>
                  <!-- end row -->
                </div>
                <!-- end card-body/ profile-user-box-->
              </div>
              <!--end profile/ card -->
            </div>
            <!-- end col-->
          </div>
          <!-- End Page Title -->
          <!-- Start Products Page -->
          <div class="row" v-if="loadingData == false">
            <div class="col-4">
              <div class="card">
                <div class="card-body">
                  <h4 class="header-title mt-0 mb-3">Contact Details:</h4>
                  
                  <div class="text-left">
                    <p class="text-muted" v-for="item in venue.Contacts" v-bind:key="item.Email">
                      <strong>{{ item.FirstName }} {{item.LastName}} :</strong>
                      <span class="ml-2">{{ item.Email }}</span>
                    </p>
                  </div>

                  <hr />
                  <h4 class="header-title mt-0 mb-3">Venue Addresses:</h4>

                  <div class="text-left">
                    <p class="text-muted" v-for="item in venue.Addresses" v-bind:key="item.addressType">
                      <strong>{{ item.addressType }}:</strong>
                      <span class="ml-2">{{ addressFormat(item) }}</span>
                    </p>
                  </div>
                  
                  <hr />
                  <h4 class="header-title mt-0 mb-3">Shipping Instructions:</h4>

                  <div class="form-group">
                    <div class="input-group">
                      <textarea type="text" class="form-control" aria-label="Shipping Instructions" v-model="venueFormTemp.ShippingInstructions">

                      </textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-body">
                  <h4 class="header-title mt-0 mb-3">Google Place Id: <small>{{ venue.GooglePlaceId }}</small></h4>
                  
                  <div class="form-group">
                    <label>Search Google Maps</label>
                    <div class="input-group">
                      <input type="text" class="form-control" aria-label="Location Name" v-model="googlePlaces.name">
                      <div class="input-group-append">
                        <button class="btn btn-dark" type="button" v-on:click="searchGooglePlaces">
                          <i class=" mdi mdi-map-search-outline"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="form-group" v-show="googlePlaces.apiLocations.length > 0">
                    <label>Found Locations</label>
                    <div class="input-group">
                      <select class="form-control select2" data-toggle="select2" v-model="googlePlaces.selectedId" :disabled="googlePlaces.apiLocations.length == 0">
                        <option v-for="item in googlePlaces.apiLocations" v-bind:key="item.place_id" v-bind:value="item.place_id">
                          {{ item.name }} ({{item.formatted_address}})
                        </option>
                      </select>
                      <div class="input-group-append">
                        <button class="btn btn-dark" type="button" v-on:click="saveGooglePlaces">
                          <i class="mdi mdi-map-check-outline"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <button type="button" class="btn btn-light" v-on:click="resetOpeningTimesButton" v-if="openingTimesCustom">
                    <i class="mdi mdi-restart mr-1"></i> Reset
                  </button>
                </div>
              </div>

              <div class="card">
                <div class="card-body">
                  <h4 class="header-title mt-0 mb-3">Opening Times:</h4>
                  
                  <div class="text-left">
                    <div class="text-muted" v-for="key in dayOrder" v-bind:key="key">
                      <div class="form-group row mb-3">
                        <label :for="key + 'OpeningInput'" class="col-3 col-form-label">{{ key }}</label>
                        <div class="col-9">
                          <input type="text" class="form-control form-control-sm" :id="key + 'OpeningInput'" v-model="venueFormTemp.OpeningTimes[key].value">
                        </div>
                      </div>
                    </div>
                  </div>

                  <button type="button" class="btn btn-light" v-on:click="resetOpeningTimesButton" v-if="openingTimesCustom">
                    <i class="mdi mdi-restart mr-1"></i> Reset
                  </button>
                </div>
              </div>
            </div>
            <!-- end col -->
            <div class="col-8">
              <div class="card">
                <div class="card-body">
                  <h4 class="header-title mt-0 mb-3">Orders:</h4>
                  <div id="clientsTable">
                    <v-client-table v-if="orders" :data="orders" :columns="columns" :options="options">
                      <div slot="ShippingDate" slot-scope="{row}">
                        {{ dayOrder[new Date(row.ShippingDate).getDay()] }} <br>
                        {{ formatDate(row.ShippingDate) }}
                      </div>
                      <div slot="Total" slot-scope="{row}">
                        ${{ Number(row.Total).toFixed(2) }}
                      </div>
                      <div slot="Action" slot-scope="{row}">
                        <router-link :to="'/admin/order/' + row.Id " class="btn text-muted d-none d-sm-inline-block btn-link font-weight-semibold">
                          <button type="button" class="btn btn-info btn-sm"><i class="mdi mdi-eye mr-1"></i> <span>View</span> </button>
                        </router-link>
                      </div>
                    </v-client-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- End Product Page -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
const venue_api_url = "/api/admin/customer/venue/";
const order_api_url = "/api/admin/order/";
const places_api_url = "/api/admin/location/";

/* eslint-disable no-console */
export default {
  props: ["clientId"],
  data: () => {
    return {
      openingTimesCustom: false,
      googlePlaces: {
        selectedId: null,
        id: null,
        name: null,
        apiLocations: [],
      },
      venueForm: {
        OpeningTimes: {},
        ShippingInstructions: null
      },
      venueFormTemp: {
        OpeningTimes: {},
        ShippingInstructions: null
      },
      venue: {},
      loadingData: true,
      columns: ['XeroInvoiceId', 'ShippitTrackingId', 'Items', 'ShippingDate', 'OrderStatus', 'Total', 'Action'],
      options: {
        filterable: ['XeroInvoiceId', 'ShippitTrackingId', 'Items', 'ShippingDate', 'OrderStatus', 'Total'],
        orderBy: {
          column: 'XeroInvoiceId',
          ascending: false
        },
        headings: {
          XeroInvoiceId: 'Xero Invoice',
          ShippitTrackingId: 'Tracking Id',
          OrderStatus: 'Status',
          ShippingDate: 'Date'
        }
      },
      dayOrder: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
      monthsFullName: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      orders: []
    }
  },
  computed: {
    totalRevenue: function() {
      let total = 0;
      for(let order of this.orders) {
        if(order.OrderStatus == 'Cancelled') {
          continue;
        }
        total += order.Total;
      }

      return total;
    }
  },
  methods: {
    loadCustomerData: function() {
      // Send GET request
      axios.get(venue_api_url + this.clientId).then(response => {
        this.venue = response.data.Venue;
        if('OpeningTimes' in this.venue) {
          this.venueFormTemp.OpeningTimes = {};
          for(let day of this.dayOrder) {
            this.venueForm.OpeningTimes[day] = this.venue.OpeningTimes.formatted[day];
            this.venueFormTemp.OpeningTimes[day] = {
              value: this.venue.OpeningTimes.formatted[day]
            }
          }
          this.openingTimesCustom = this.venue.OpeningTimes.custom;
        }
        else {
          for(let day of this.dayOrder) {
            this.venueFormTemp.OpeningTimes[day] = {
              value: ''
            }
          }
        }

        if('ShippingInstructions' in this.venue) {
          this.venueFormTemp.ShippingInstructions = this.venue.ShippingInstructions;
        }

        if('GooglePlaceId' in this.venue) {
          this.googlePlaces.id = this.venue['GooglePlaceId'];
        }
        this.googlePlaces.name = this.venue.Name;
        return axios.get(order_api_url + "?venueId=" + this.clientId);
      }).then(response => {
        this.orders = response.data.Items;
        this.loadingData = false;
      });
    },
    resetOpeningTimesButton: function() {
      axios.post(venue_api_url + this.clientId, {
        OpeningTimesReset: true
      }).then(response => {          
        if(response.data.success) {
          $.NotificationApp.send(
            "Success!",
            "You've successfully reset the opening times to their original values!",
            "top-center", "rgb(0,0,0)",
            "success"
          );
          this.loadCustomerData();
        }
        else {
          $.NotificationApp.send(
            "Failure!",
            "There was an issue making your changes..",
            "top-center", "rgb(0,0,0)",
            "error"
          );
        }
      });
    },
    searchGooglePlaces: function() {
      axios
        .get(places_api_url + encodeURI(this.googlePlaces.name))
        .then(response => {
          this.googlePlaces.apiLocations = response.data.locations;
        });
    },
    saveGooglePlaces: function() {
      axios
        .post(venue_api_url + this.clientId + '/location', {
          placeId: this.googlePlaces.selectedId
        })
        .then(response => {
          if(response.data.success) {
            $.NotificationApp.send(
              "Success!",
              "You've successfully changed the Google Place Mapping!",
              "top-center", "rgb(0,0,0)",
              "success"
            );
            this.loadCustomerData();
            this.googlePlaces.apiLocations = [];
          }
          else {
            $.NotificationApp.send(
              "Failure!",
              response.data.error,
              "top-center", "rgb(0,0,0)",
              "error"
            );
          }
        });
    },
    saveChangesFunction: function() {
      for(let day of this.dayOrder) {
        this.venueForm.OpeningTimes[day] = this.venueFormTemp.OpeningTimes[day].value;
      }

      // Only include shipping instructions in the POST data when it's been changed
      if(this.venueFormTemp.ShippingInstructions !== this.venue.ShippingInstructions) {
        this.venueForm.ShippingInstructions = this.venueFormTemp.ShippingInstructions;
      }

      axios
        .post(venue_api_url + this.clientId, this.venueForm)
        .then(response => {
          if(response.data.success) {
            $.NotificationApp.send(
              "Success!",
              "You've successfully changed the client information!",
              "top-center", "rgb(0,0,0)",
              "success"
            );
            this.loadCustomerData();
          }
          else {
            $.NotificationApp.send(
              "Failure!",
              "There was an issue making your changes..",
              "top-center", "rgb(0,0,0)",
              "error"
            );
          }
        });
    },
    formatDate: function(data) {
      let date = new Date(data);
      return [
        date.getDate(),
        this.monthsFullName[date.getMonth()],
        date.getFullYear()
      ].join(" ");
    },
    addressFormat: function(address) {
      let addlist = [
        'addressLine1',
        'addressLine2',
        'city',
        'region',
        'postalCode'
      ];

      let output = [];

      for(let key of addlist) {
        if(
          key in address &&
          address[key] !== undefined &&
          address[key] != ''
        ) {
          output.push(address[key]);
        }
      }

      return output.join(', ');
    }
  },
  mounted() {
    this.loadCustomerData();
  },
};
</script>
