/* eslint-env jquery */

<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>
        
        <!-- Start Content-->
        <div class="container-fluid">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Orders List</h4>
              </div>
            </div>
          </div>
          <!-- End Page Title --> 
          <!-- Start Loading / Errors -->
          <div class="d-flex justify-content-center" v-if="apiResponse.loading">
            <div class="spinner-border avatar-lg text-primary" role="status"></div>
          </div>
          <div class="row" v-else-if="apiResponse.error">
            <div class="col-12">
              <div class="card">
                <!-- Start Error Message -->
                <div class="card-body">
                  <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">Api Error!</h4>
                    <p>There was an unexpected error when loading data from the API. Click the button below to try again.</p>
                    <p class="mb-0">
                      <button type="button" class="btn btn-info" v-on:click="fetchData">
                        <i class="mdi mdi-refresh mr-1"></i> Retry
                      </button>
                    </p>
                  </div>
                </div>
                <!-- End Error Message -->
              </div>
            </div>
          </div>
          <!-- End Loading / Errors -->
          <!-- Start Orders Page -->
          <div class="row" v-else>
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div id="clientsTable">
                    <v-client-table v-if="orders" :data="orders" :columns="columns" :options="options">
                      <div slot="Total" slot-scope="{row}">
                        {{ Number(row.Total).toFixed(2) }}
                      </div>
                      <div slot="Action" slot-scope="{row}">
                        <router-link :to="'/order/' + row.Id " class="btn text-muted d-none d-sm-inline-block btn-link font-weight-semibold">
                          <button type="button" class="btn btn-info btn-sm"><i class="mdi mdi-eye mr-1"></i> <span>View</span> </button>
                        </router-link>
                      </div>
                    </v-client-table>
                  </div>
                </div> <!-- end card-body-->
              </div> <!-- end card-->
            </div> <!-- end col -->
          </div>
          <!-- End Product Page -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
const orders_api_url = '/api/client/order';

export default {
  data() {
    return {
      apiResponse: {
        loading: true,
        error: false
      },
      columns: ['Username', 'XeroInvoiceId', 'ShippitTrackingId', 'Products', 'ShippingDate',  'Total', 'Action'],
      orders: [],
      options: {
        filterable: ['Username', 'XeroInvoiceId', 'ShippitTrackingId', 'Products', 'ShippingDate', 'Total'],
        orderBy: {
          column: 'XeroInvoiceId',
          ascending: false
        },
        headings: {
          XeroInvoiceId: 'Xero Invoice',
          ShippitTrackingId: 'Tracking Id',
          Username: 'User',
          ShippingDate: 'Date'
        }
      }
    }
  },
  methods: {
    fetchData: function() {
      this.apiResponse.loading = true;
      this.apiResponse.error = false;

      axios.get(orders_api_url)
        .then(response => {
          this.orders = response.data.Items;
          this.apiResponse.loading = false;
        })
        .catch(error => {
          if (error) {
            this.apiResponse.error = true;
            this.apiResponse.loading = false;
          }
        });
    }
  },
  mounted() {
    this.fetchData();
  }
}
</script>
