<template>
  <div class="account-pages mt-5 mb-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="card">
            <!-- Logo-->
            <div class="card-header pt-4 pb-4 text-center bg-dark">
              <a href="index.html">
                <span><img src="/assets/images/logo-wide.svg" alt="" height="40"></span>
              </a>
            </div>

            <div class="card-body p-4">
              
              <div class="text-center w-75 m-auto">
                <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Wholesale Portal Log In</h4>
              </div>

              <div v-show="loginSuccess" class="alert alert-dismissible alert-success" role="alert">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <strong>Success - </strong> You were successfully logged in!
              </div>
              <div v-show="loginFailed" class="alert alert-dismissible alert-danger" role="alert">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <strong>Error - </strong> Failed to Login!
              </div>
              <div v-show="newPasswordRequired" class="alert alert-dismissible alert-info" role="alert">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <strong>Info - </strong> Password Change Required!
              </div>

              <form v-on:submit.prevent="loginFunction">

                <div class="form-group text-center spinner d-none">
                  <div class="spinner-border avatar-md text-secondary" role="status"></div>
                </div>

                <div class="form-group email">
                  <label for="emailaddress">Email address</label>
                  <input class="form-control" v-model="email" type="email" id="emailaddress" required placeholder="Enter your email address">
                </div>

                <div class="form-group password">
                  <label for="password">Password</label>
                  <div class="input-group input-group-merge">
                    <input type="password" v-model="password" id="password" class="form-control" placeholder="Enter your password">
                    <div class="input-group-append" data-password="false">
                      <div class="input-group-text">
                        <span class="password-eye"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group password" v-if="newPasswordRequired">
                  <label for="password">New Password</label>
                  <div class="input-group input-group-merge">
                    <input type="password" v-model="new_password" id="password" class="form-control" placeholder="Enter a new password" min=8>
                    <div class="input-group-append" data-password="false">
                      <div class="input-group-text">
                        <span class="password-eye"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-0 text-center signup">
                  <button class="btn btn-primary"> Login </button>
                </div>

              </form>
            </div> <!-- end card-body -->
          </div>
          <!-- end card -->
        </div> <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </div>
  <!-- end page -->
</template>

<script>
const axios = require('axios').default;
export default {
  data() {
    return {
      email: null,
      password: null,
      new_password: null,
      loginSuccess: false,
      loginFailed: false,
      newPasswordRequired: false,
    }
  },
  methods: {
    loginFunction: function() {
      this.loginSuccess = false;
      this.loginFailed = false;
      // Setup proper authentication
      var api_url = '/api/client/auth/login/';
      // Send POST request
      let post_data = {
        username: this.email,
        password: this.password
      };

      if(this.newPasswordRequired) {
        post_data["new_password"] = this.new_password;
      }

      axios
        .post(api_url, post_data)
        .then(response => {
          if(response.data.success) {
            for (let [key, value] of Object.entries(response.data.tokens)) {
              let itemValue = value;
              if(typeof value === 'object' && value !== null) {
                itemValue = JSON.stringify(value);
              }
              localStorage.setItem(key, itemValue);
            }
            this.loginSuccess = true;
            let loginRedirect = 'home';
            if(localStorage.hasOwnProperty('last_page')) {
              loginRedirect = localStorage.last_page;
              localStorage.removeItem('last_page');
            }
            this.$router.push({ name: loginRedirect });
          }
          else if(response.data.new_password_required) {
            this.newPasswordRequired = true;
          }
          else {
            this.loginFailed = true;
            this.newPasswordRequired = false;
          }
        });
    }
  },
  mounted() {
  }
}
</script>
