/* eslint-env jquery */

<template>
  <div class="wrapper">
    <side-navbar></side-navbar>
    <!-- ============================================================== -->
    <!-- Start Page Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
      <div class="content">
        <main-header></main-header>
        
        <div class="container-fluid" v-if="productLoading">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Loading Product...</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid" v-else-if="!productFound">
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Product Not Found...</h4>
              </div>
            </div>
          </div>
        </div>
        <!-- Start Content-->
        <div class="container-fluid" v-else>
          <!-- Start Page Title -->
          <div class="row">
            <div class="col-12">
              <div class="page-title-box">
                <h4 class="page-title">Product - {{ product.Name }}</h4>
              </div>
            </div>
          </div>
          <!-- End Page Title --> 
          <!-- Start Product Page -->
          <div class="d-flex justify-content-center" v-show="!product.Name">
            <div class="spinner-border avatar-lg text-primary" role="status" v-show="!product.Name"></div>
          </div>
          <div class="row" v-show="product.Name">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <!-- Product image -->
                      <a class="text-center d-block mb-4">
                        <img v-bind:src="product.Image" class="img-fluid" v-bind:alt="product.Name" />
                      </a>
                    </div> <!-- end col -->
                    <div class="col-lg-8">
                      <form class="pl-lg-4">
                        <!-- Product title -->
                        <h3 class="mt-0">{{ product.Name }} 
                          <span class="badge badge-success-lighten" v-if="product.InStock">In Stock</span>
                          <span class="badge badge-danger-lighten" v-else-if="product.ComingSoon">Coming Soon</span>
                          <span class="badge badge-danger-lighten" v-else>No Stock</span>
                        </h3>
                        <p class="mb-1">Added Date: 09/12/2018</p>

                        <!-- Product description -->
                        <div class="mt-4">
                          <h6 class="font-14">Price:</h6>
                          <h3> ${{ Number(product.Price).toFixed(2) }}</h3>
                        </div>

                        <!-- Quantity -->
                        <div class="mt-4" v-if="product.InStock">
                          <h6 class="font-14">Quantity</h6>
                          <form v-on:submit.prevent="addToCart(product)" class="form-group">
                            <div class="d-flex">
                              <input type="number" class="form-control" placeholder="Qty" style="width: 90px;" v-model="productQuantity" min="1" value="1">
                              <button class="btn btn-danger ml-2"><i class="mdi mdi-cart mr-1"></i> Add to cart</button>
                            </div>
                          </form>
                        </div>

                        <!-- Product description -->
                        <div class="mt-4">
                          <h6 class="font-14">Description:</h6>
                          <p>
                            {{ product.Description }}
                          </p>
                        </div>

                        <!-- Product information -->
                        <div class="mt-4">
                          <div class="row">
                            <div class="col-md-4">
                              <h6 class="font-14">Quantity:</h6>
                              <p class="text-sm lh-150">{{ product.Quantity }} </p>
                            </div>
                            <div class="col-md-4">
                              <h6 class="font-14">Category:</h6>
                              <p class="text-sm lh-150">{{ product.Category }}</p>
                            </div>
                          </div>
                        </div>

                      </form>
                    </div> <!-- end col -->
                  </div> <!-- end row-->
                </div> <!-- end card-body-->
              </div> <!-- end card-->
            </div> <!-- end col-->
          </div>
          <!-- End Product Page -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;
export default {
  props: [
    'productId',
    'variantId'
  ],
  data() {
    return {
      productFound: false,
      productLoading: true,
      productQuantity: 0,
      product: {},
    }
  },
  methods: {
    addToCart: function (item) {
      // Send POST request
      var api_url = '/api/client/cart';
      axios
        .put(api_url, {
          Image: item.Image,
          ProductId: item.Id,
          VariantId: item.VariantId,
          SKU: item.SKU,
          Unit: item.Quantity,
          Quantity: this.productQuantity,
          Price: item.Price,
          Name: item.Name
        })
        .then(response => {
          $.NotificationApp.send(
            "Success!",
            "You have added " + item.Name + " (" + item.Quantity + ") into your cart for a total of " + response.data.Count + " items.",
            "top-center", "rgb(0,0,0)",
            "success"
          );
          localStorage.currumbin_cart_cache = response.data.Count;
          this.productQuantity = 0;
        });
    }
  },
  mounted() {
    var api_url = '/api/client/product/' + this.productId + '/' + this.variantId;
    // Send POST request
    axios
      .get(api_url)
      .then(response => {
        this.product = response.data
        this.productFound = true;
        this.productLoading = false;
      })
      .catch(error => {
        this.productLoading = false;
        if (error.response && error.response.status == 404) {
          this.productFound = false;
        }
      });
  }
}
</script>
